import React, {Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {LinearProgress, withStyles} from "@material-ui/core";
import withClientsDetail from "../../../providers/withClientsDetail";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = {
    welcome: {
        color: "#05092C",
    },

    title: {
        fontSize: "18px",
        fontWeight: "bold",
        lineHeight: "1",
    },
};

const DetailClient = (props) => {
    const {classes} = props;
    return (
        <Fragment>
            {props.loading ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LinearProgress/>
                    </Grid>
                </Grid>
                :
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Card>
                            <CardHeader
                                disableTypography={true}
                                className={classes.title}
                                title={"Cliente"}
                            />
                            <Divider light={true}/>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" component="h6">
                                            Id
                                        </Typography>
                                        <Typography variant={"body2"} color="textSecondary" component="p">
                                            {props.clientDetail.id}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" component="h6">
                                            Username
                                        </Typography>
                                        <Typography variant={"body2"} color="textSecondary" component="p">
                                            {props.clientDetail.username}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" component="h6">
                                            Email
                                        </Typography>
                                        <Typography variant={"body2"} color="textSecondary" component="p">
                                            {props.clientDetail.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card>
                            <CardHeader
                                disableTypography={true}
                                className={classes.title}
                                title={"Acciones"}
                            />
                            <Divider light={true}/>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            color="primary"
                                            className={classes.submit}
                                            onClick={() => props.history.push(`/Clients/${props.clientDetail.id}`)}
                                        >Editar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider light={true}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            fullWidth
                                            color="inherit"
                                            className={classes.submit}
                                            onClick={() => props.history.push("/Clients")}
                                        >
                                            Volver
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12}>
                    </Grid>
                </Grid>
            }
        </Fragment>
    )
};

export default withClientsDetail(withStyles(styles)(DetailClient));