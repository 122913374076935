import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import {withLogin} from "../../../providers";
import {CircularProgress} from "@material-ui/core";
import {Redirect} from "react-router";
import {withSnackbar} from "notistack";
import language from "../../../store/helpers/language"

let theme = createMuiTheme({
    palette: {
        primary: {
            light: '#63ccff',
            main: '#04BC00',
            dark: "#04BC05",
            contrastText: "white",
        },
    },
    shape: {
        borderRadius: 8,
    },
});

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(/img/fondo.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    logo: {
        bottom: 0,
        position: "fixed",
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "#05092C",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    link: {
        color: "#04BC00",
        textDecoration: "none",
    }
}));

function Login(props) {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" className={classes.root}>
                <CssBaseline/>
                <Grid item xs={false} sm={4} md={7} className={classes.image}>
                    <img src={"/img/logo.png"} className={classes.logo} alt={"fondo"}/>
                </Grid>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Resell Go
                        </Typography>
                        <form className={classes.form} noValidate autoComplete={"off"} onSubmit={(e) => {
                            props.handleSubmit(e)
                        }}>
                            {props.integrationPhase}
                            <TextField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                id="username"
                                label={language[props.language].Email}
                                name="username"
                                autoFocus
                                onChange={props.handleChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                name="password"
                                label={language[props.language].Password}
                                type="password"
                                id="password"
                                onChange={props.handleChange}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                color="primary"
                                className={classes.submit}
                            >
                                {props.loading ?
                                    <CircularProgress
                                        size={24}
                                        color={"secondary"}/>
                                    : language[props.language].Login
                                }
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link to="/remember" className={classes.link}>
                                        {language[props.language].Remember}
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link to="/registro" className={classes.link}>
                                        {language[props.language].CreateAccount}
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Grid>
                {props.loggedIn && (
                    <Redirect to={{pathname: '/Dashboard'}}/>
                )}
            </Grid>
        </ThemeProvider>
    );
}

export default withSnackbar(withLogin(Login))