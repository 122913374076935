import React, {Fragment} from 'react';
import withGlobal from "../../../providers/withGlobal";
import {Typography, withStyles, CircularProgress, LinearProgress} from "@material-ui/core";
import language from "../../../store/helpers/language";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from '@material-ui/core/Divider';
import InputLocalFull from '../../local/InputLocalFull';
import Button from "@material-ui/core/Button";
import Chart from "react-google-charts";
// import FormGroup from "@material-ui/core/FormGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import {Link} from 'react-router-dom'


const styles = {
    welcome: {
        color: "#05092C",
    },

    title: {
        fontSize: "18px",
        fontWeight: "bold",
        lineHeight: "1",
    }
};

const Dashboard = (props) => {
    const {classes} = props;
    const [token, setToken] = React.useState('');
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography color={"inherit"} variant={"h4"} className={classes.welcome}>
                        ¡{language[props.language].Welcome}!
                    </Typography>
                    <Typography color={"textPrimary"} variant={"subtitle2"}>
                        Utilice la herramienta de navegación a la izquierda para navegar a la zona deseada. Con el
                        "Inicio
                        Rápido"
                        se puede registrar nuevos dominios, modificar datos de dominios existentes o hacer una solicitud
                        Whois
                        fácilmente.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6} sm={12} lg={6} xl={6}>
                    <Card>
                        <CardHeader
                            disableTypography={true}
                            className={classes.title}
                            title="Inicio Rápido"
                        />
                        <Divider light={true}/>
                        <CardContent>
                            <form onSubmit={() => console.log()} autoComplete={"off"}>
                                <Grid container spacing={1}>
                                    <InputLocalFull
                                        type={"text"}
                                        name={"domain"}
                                        id={"domain"}
                                        label={"Dominio"}
                                        required={true}
                                        placeholder={"Dominio"}
                                        handleChange={(e) => setToken(e.target.value)}
                                    />
                                    <Grid item xs={4}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            color="primary"
                                            className={classes.submit}
                                            onClick={() => window.open(`/dominios?token=${token}`)}
                                        >
                                            {props.loading ?
                                                <CircularProgress
                                                    size={24}
                                                    color={"secondary"}/>
                                                : "Consultar"
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} sm={12} lg={6} xl={6}>
                    <Card>
                        <CardHeader
                            disableTypography={true}
                            className={classes.title}
                            title="Estadistica"
                        />
                        <Divider light={true}/>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Typography variant={"subtitle2"}>Fecha ultima actualización </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant={"subtitle2"}>2019/07/19 15:13:00 </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Link to={"/Domains"}>Total Dominios</Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant={"subtitle2"}>13</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Link to={"/Contacts"}>Dominios Expirados</Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant={"subtitle2"}>27</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Link to={"/Contacts"}>Contactos de dominio</Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant={"subtitle2"}>0</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} sm={12} lg={6} xl={6}>
                    <Card>
                        <CardHeader
                            disableTypography={true}
                            className={classes.title}
                            title="Dominios Vencidos"
                        />
                        <Divider light={true}/>
                        <CardContent>
                            <form onSubmit={() => console.log()} autoComplete={"off"}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Typography variant={"subtitle2"}>prueba.com </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant={"subtitle2"}>2019/07/19 15:13:00 </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Typography variant={"subtitle2"}>prueba.com </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant={"subtitle2"}>2019/07/19 15:13:00 </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Typography variant={"subtitle2"}>prueba.com </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant={"subtitle2"}>2019/07/19 15:13:00 </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Typography variant={"subtitle2"}>prueba.com </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant={"subtitle2"}>2019/07/19 15:13:00 </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            color="primary"
                                            className={classes.submit}
                                        >
                                            {props.loading ?
                                                <CircularProgress
                                                    size={24}
                                                    color={"secondary"}/>
                                                : "Renovar"
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                                {/*<Grid container>*/}
                                {/*    <InputLocalFull*/}
                                {/*        type={"text"}*/}
                                {/*        name={"multiWhois"}*/}
                                {/*        id={"multiWhois"}*/}
                                {/*        label={"Multi Whois"}*/}
                                {/*        required={true}*/}
                                {/*        placeholder={"Multi Whois"}*/}
                                {/*        handleChange={(e) => console.log(e.target.name)}*/}
                                {/*    />*/}
                                {/*    <FormGroup row>*/}
                                {/*        <FormControlLabel*/}
                                {/*            control={*/}
                                {/*                <Checkbox checked={false}*/}
                                {/*                          onChange={(e) => console.log(e.target.name)}*/}
                                {/*                          value="checkedA"/>*/}
                                {/*            }*/}
                                {/*            label="Solo estos dominios"*/}
                                {/*        />*/}
                                {/*        <FormControlLabel*/}
                                {/*            control={*/}
                                {/*                <Checkbox checked={false}*/}
                                {/*                          onChange={(e) => console.log(e.target.name)}*/}
                                {/*                          value="checkedA"/>*/}
                                {/*            }*/}
                                {/*            label="También comprobar sin guión"*/}
                                {/*        />*/}
                                {/*        <FormControlLabel*/}
                                {/*            control={*/}
                                {/*                <Checkbox checked={false}*/}
                                {/*                          onChange={(e) => console.log(e.target.name)}*/}
                                {/*                          value="checkedA"/>*/}
                                {/*            }*/}
                                {/*            label="También comprobar con caracteres especiales convertidos"*/}
                                {/*        />*/}
                                {/*    </FormGroup>*/}
                                {/*</Grid>*/}
                            </form>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} sm={12} lg={6} xl={6}>
                    <Card>
                        <CardHeader
                            disableTypography={true}
                            className={classes.title}
                            title="Grafico"
                        />
                        <Divider light={true}/>
                        <CardContent>
                            <Chart
                                width={'500px'}
                                height={'300px'}
                                chartType="Bar"
                                loader={<LinearProgress/>}
                                data={[
                                    ['MES', 'DOMINIOS', 'MONTO TOTAL'],
                                    ['1', 35, 100],
                                    ['2', 40, 100],
                                    ['3', 50, 100],
                                    ['4', 25, 100],
                                    ['5', 30, 100],
                                    ['6', 30, 100],
                                    ['7', 56, 100],
                                    ['8', 35, 100],
                                    ['9', 24, 100],
                                    ['10', 27, 100],
                                    ['11', 54, 100],
                                    ['12', 23, 100],
                                ]}
                                options={{
                                    // Material design options
                                    chart: {
                                        title: 'ResellGo',
                                        subtitle: 'Dominios y ventas del año 2019',
                                    },
                                }}
                                // For tests
                                rootProps={{'data-testid': '2'}}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} sm={12} lg={6} xl={6}>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default withGlobal(withStyles(styles)(Dashboard));