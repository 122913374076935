import React, {Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import language from '../../../store/helpers/language';
import {CircularProgress, LinearProgress, withStyles} from "@material-ui/core";
import withClientsNew from "../../../providers/withClientsNew";
import Button from "@material-ui/core/Button";
import InputLocal from "../../local/InputLocal";

const docs = [
    {value: "1", name: "Colombia"},
];

const styles = {
    welcome: {
        color: "#05092C",
    },

    title: {
        fontSize: "18px",
        fontWeight: "bold",
        lineHeight: "1",
    },
};

const NewClient = (props) => {
    const {classes} = props;
    return (
        <Fragment>
            {props.loadingPage ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LinearProgress/>
                    </Grid>
                </Grid>
                :
                <Grid container spacing={2}>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Card>
                            <CardHeader
                                disableTypography={true}
                                className={classes.title}
                                title={language[props.language].BasicDat}
                            />
                            <Divider light={true}/>
                            <CardContent>
                                <form onSubmit={props.handleSubmit} autoComplete={"off"}>
                                    <Grid container spacing={1}>
                                        <InputLocal
                                            type={"text"}
                                            name={"documento"}
                                            id={"documento"}
                                            label={"Documento"}
                                            required={false}
                                            placeholder={"Número de documento"}
                                            handleChange={(e) => props.changeClientInput(e.target.name, e.target.value)}
                                        />
                                        <InputLocal
                                            type={"text"}
                                            name={"titulo"}
                                            id={"titulo"}
                                            label={"Titulo"}
                                            required={false}
                                            placeholder={"Titulo"}
                                            handleChange={(e) => console.log(e.target.name)}
                                        />
                                        <InputLocal
                                            type={"text"}
                                            name={"nombre"}
                                            id={"nombre"}
                                            label={"Nombres"}
                                            required={true}
                                            placeholder={"Nombre del cliente"}
                                            handleChange={(e) => console.log(e.target.name)}
                                        />
                                        <InputLocal
                                            type={"text"}
                                            name={"apellido"}
                                            id={"apellido"}
                                            label={"Apelidos"}
                                            required={true}
                                            placeholder={"Apellido del cliente"}
                                            handleChange={(e) => console.log(e.target.name)}
                                        />
                                        <InputLocal
                                            type={"text"}
                                            name={"organizacion"}
                                            id={"organizacion"}
                                            label={"Organicación"}
                                            required={true}
                                            placeholder={"Organización"}
                                            handleChange={(e) => console.log(e.target.name)}
                                        />
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Card>
                            <CardHeader
                                disableTypography={true}
                                className={classes.title}
                                title={language[props.language].Address}
                            />
                            <Divider light={true}/>
                            <CardContent>
                                <form onSubmit={props.handleSubmit}>
                                    <Grid container spacing={1}>
                                        <InputLocal
                                            options={docs}
                                            type={"select"}
                                            name={"pais"}
                                            id={"pais"}
                                            label={"Pais"}
                                            placeholder={"Pais"}
                                            handleChange={(e) => console.log(e.target.name)}
                                        />
                                        <InputLocal
                                            type={"text"}
                                            name={"codArea"}
                                            id={"codArea"}
                                            label={"Código de area"}
                                            required={true}
                                            placeholder={"Código de area"}
                                            handleChange={(e) => console.log(e.target.name)}
                                        />
                                        <InputLocal
                                            type={"text"}
                                            name={"codPostal"}
                                            id={"codPostal"}
                                            label={"Código postal"}
                                            required={true}
                                            placeholder={"Código postal"}
                                            handleChange={(e) => console.log(e.target.name)}
                                        />
                                        <InputLocal
                                            type={"text"}
                                            name={"ciudad"}
                                            id={"ciudad"}
                                            label={"Ciudad"}
                                            required={true}
                                            placeholder={"Ciudad"}
                                            handleChange={(e) => console.log(e.target.name)}
                                        />
                                        <InputLocal
                                            type={"text"}
                                            name={"direccion"}
                                            id={"direccion"}
                                            label={"Dirección"}
                                            required={true}
                                            placeholder={"Dirección"}
                                            handleChange={(e) => console.log(e.target.name)}
                                        />
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Card>
                            <CardHeader
                                disableTypography={true}
                                className={classes.title}
                                title={language[props.language].Communication}
                            />
                            <Divider light={true}/>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <InputLocal
                                        type={"text"}
                                        name={"telefono"}
                                        id={"telefono"}
                                        label={"Telefono"}
                                        required={true}
                                        placeholder={"Telefono"}
                                        handleChange={(e) => console.log(e.target.name)}
                                    />
                                    <InputLocal
                                        type={"text"}
                                        name={"movil"}
                                        id={"movil"}
                                        label={"Movil"}
                                        required={true}
                                        placeholder={"Movil"}
                                        handleChange={(e) => console.log(e.target.name)}
                                    />
                                    <InputLocal
                                        type={"text"}
                                        name={"fax"}
                                        id={"fax"}
                                        label={"Fax"}
                                        required={true}
                                        placeholder={"Fax"}
                                        handleChange={(e) => console.log(e.target.name)}
                                    />
                                    <InputLocal
                                        value={props.clientNew.email}
                                        type={"email"}
                                        name={"email"}
                                        error={props.error.email !== undefined}
                                        errorMessage={props.error.email}
                                        id={"emailClient"}
                                        label={"Correo electrónico"}
                                        disabled={props.clientNew.id > 0}
                                        required={true}
                                        placeholder={"Correo electrónico"}
                                        handleChange={(e) => props.handleInputChange(e)}
                                    />
                                    <InputLocal
                                        type={"email"}
                                        name={"emailSecundario"}
                                        id={"emailSecundario"}
                                        label={"Correo electrónico secundario"}
                                        required={true}
                                        placeholder={"Correo electrónico secundario"}
                                        handleChange={(e) => console.log(e.target.name)}
                                    />
                                    <InputLocal
                                        error={props.error.password !== undefined}
                                        errorMessage={props.error.password}
                                        type={"password"}
                                        name={"password"}
                                        id={"password"}
                                        label={"Contraseña"}
                                        required={true}
                                        placeholder={"Contraseña"}
                                        handleChange={(e) => props.handleInputChange(e)}
                                    />

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            color="primary"
                            className={classes.submit}
                            onClick={(e) => props.handleSubmit(e)}
                        >
                            {props.loading ?
                                <CircularProgress
                                    size={24}
                                    color={"secondary"}/>
                                : "Guardar"
                            }
                        </Button>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Button
                            type="button"
                            variant="outlined"
                            fullWidth
                            color="inherit"
                            className={classes.submit}
                            onClick={() => props.history.push("/Clients")}
                            disabled={props.loading}
                        >
                            Cancelar
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                    </Grid>
                </Grid>
            }
        </Fragment>
    )
};

export default withClientsNew(withStyles(styles)(NewClient));