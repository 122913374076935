import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {changeClient, changeClientInput} from '../store/actions/clientsActions';
import {serviceApiResponse} from "../services/request";

const mapStateToProps = ({global: {language}, client: {client}}) => ({
    client,
    language
});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeClientInput,
    changeClient,
}, dispatch);


export default WrappedComponent => (connect(mapStateToProps, mapDispatchToProps)(
    class extends React.PureComponent {

        constructor(props) {
            super(props);

            this.state = {
                clientDetail: {},
                loading: false,
            };
        }

        componentDidMount() {
            this.setState({clientDetail: this.props.client});
            if (this.props.client.id === "") {
                const id = window.location.pathname.split("/").slice(-1).pop();
                this.setState({loading: true});
                serviceApiResponse({}, `users/${id}`, "GET")
                    .then(response => {
                        if (response.id) {
                            this.props.changeClient(response);
                            this.setState({clientDetail: response, loading: false});
                        } else {
                            this.props.handleMessage("No se pudo consultar el cliente", "error")
                        }
                    })
                    .catch(error => {
                        this.props.handleMessage(error.message, "error")
                        this.setState({loading: false});
                    })
            }
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    {...this.state}
                />
            );
        }
    }
));