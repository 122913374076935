/** @jsx jsx */
import {css, jsx} from '@emotion/core';

import {useState} from "react";
import styled from "@emotion/styled";
import {COLORS, FLEX, Mask, ELASTIC, Btn} from "../../ui";
import {Link, withRouter} from "react-router-dom";
import Myselect, {CurrencySelect} from "./myselect";
import ShowModalComp from "../../menuMovil"
import withGlobal from "../../../providers/withGlobal";


const Items = styled.div`
    position:absolute;
    top:0;
    right:0px;
    width:20px;
    height:20px;
    border-radius:12.5px;
    background-color:${COLORS.verde};
    text-align:center;
    color:white;
    line-height:20px;
    font-size:12px;
    transform:translateY(-25%);
    cursor:pointer;
`

const Menudesk = styled.div`
    background-color:rgba(0,0,0,.5);
   
    a{color:white; padding: .5em .75em;font-size:15px;cursor:pointer;};
`

const Cart = ({items = 0}) => {
    return (
        <ELASTIC flex={"0 0 45px"} className={"position-relative"}>
            <Link to={"/checkout"} className={"col-12 px-0"} disabled={items === 0}>
                <Mask img={"cart"}
                      recuadro={"cuadro"}
                />
            </Link>
            {items !== 0 &&
            <Items>

                {items}
            </Items>}
        </ELASTIC>
    )
}


export const rutas = [
    {
        url: "/",
        texto: "Inicio"
    },
    {
        url: "/nosotros",
        texto: "Nosotros"
    }
    , {
        url: "/dominios",
        texto: "Dominios"
    }
    , {
        url: "/transferir",
        texto: "Transferir"
    }
    , {
        url: "/precios",
        texto: "Precios"
    }
    , {
        url: "/formas_de_pago",
        texto: "Formas de pago"
    }
    , {
        url: "/promociones",
        texto: "Promociones"
    }
    , {
        url: "/contacto",
        texto: "Contacto"
    }

]


const Myheader = styled.header` 
        width:100%;
        background:${COLORS.azul};
`

const Zup = css`
    position:relative;
    z-index:9;
`


const Header = (props) => {

    const [open, set] = useState(false);
    const path = props.history.location.pathname;

    return (
        <div css={Zup} className={`col-12 px-0 ${(path === "/login" || path === "/registro") && "d-none"}`}>
            <Myheader className={"py-2"}>

                <FLEX className={"container px-md-0"}>
                    <Link to={"/"} className="col-4 col-md-2 px-0 pr-xl-4 ">
                        <Mask
                            img={"logoL"}
                            recuadro={"recuadro"}
                            position={"100%"}
                        />
                    </Link>
                    <FLEX className={"col-8 col-md-10 px-0 "} jc={"flex-end"}>
                        <ELASTIC flex={"0 0 95px "} className={"mr-4  d-none d-lg-block"}>
                            <Myselect value={props.language} changeLanguage={(e) => props.changeLanguage(e)}/>
                        </ELASTIC>
                        <ELASTIC flex={"0 0 110px "} className={"mr-4  d-none d-lg-block"}>
                            <CurrencySelect value={props.currency} changeCurrency={(e) => props.changeCurrency(e)}/>
                        </ELASTIC>
                        <ELASTIC className={"mr-4 d-none d-lg-block"} flex={"0 0 150px"}>
                            <Btn hg={"35px"}
                                 onClick={() => {
                                     props.location.pathname === "/checkout" ? localStorage.setItem("checkout", "1") : localStorage.setItem("checkout", "0");
                                     props.history.push(props.userActive !== null ? "/Dashboard" : "/login")
                                 }}>
                                {props.userActive !== null ? props.userActive.user.username : "Ingresar"}
                            </Btn>
                        </ELASTIC>

                        <Cart items={props.orderItems}/>

                        <ELASTIC className={" ml-2 d-lg-none"} flex={"0 0 45px "} onClick={() => set(!open)}>
                            <Mask img={"iconos01"} position={"100%"} recuadro={"cuadro"}>

                            </Mask>
                        </ELASTIC>
                    </FLEX>
                </FLEX>
            </Myheader>
            <Menudesk className={"d-none d-lg-block"}>
                <FLEX className={"container "}>
                    {rutas.map(item => <Link key={item.texto} to={item.url}>{item.texto}</Link>)}
                </FLEX>
            </Menudesk>

            <ShowModalComp
                open={open}
                history={props.history}
                set={() => set(!open)}
            />
        </div>
    )
}

export default withRouter(withGlobal(Header));