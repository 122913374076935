import React from 'react';
import {Link} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import language from "../../store/helpers/language";


const useStyles = makeStyles(theme => ({
    root: {
        justifyContent: 'center',
        flexWrap: 'wrap',
        lineHeight: "0",
    },
    paper: {
        padding: theme.spacing(1, 2),
    },
}));


const SimpleBreadcrumbs = (props) => {

    const {history} = props;
    const classes = useStyles();
    const path = history.location.pathname;
    const paths = path.split('/').map((p, i, arr) => {
        if (i === 0) return {
            key: i,
            content: (<Link to={'/Dashboard'}>{language[props.language].Dashboard}</Link>),
            active: (i === arr.length - 1),
            link: (i < arr.length - 1)
        };

        if (i === arr.length - 1) return {
            key: i,
            content: language[props.language][p] || p,
            active: (i === arr.length - 1)
        };

        return {
            key: i,
            content: (p !== "Detail" && p !== "Renew" ?
                <Link
                    to={`${arr.slice(0, i + 1).join('/')}`}>{language[props.language][p] || p}</Link> : language[props.language][p] || p),
            active: (i === arr.length - 1),
            link: (i < arr.length - 1)
        }
    });

    return (
        <div className={classes.root}>
            <Paper elevation={0} className={classes.paper}>

                <Breadcrumbs aria-label="Breadcrumb">
                    {paths.map(function (index) {

                        return <div key={index}>{index.content}</div>;
                    })}

                </Breadcrumbs>
            </Paper>
            <br/>
        </div>
    );
}

export default SimpleBreadcrumbs;
