const language = {
    ES: {
        Email: "Correo electrónico",
        Password: "Contraseña",
        Login: "Iniciar Sesión",
        Remember: "¿Olvidaste tu contraseña?",
        CreateAccount: "¿Aún no tienes una cuenta?",
        EmailPasswordRequired: "El correo electrónico y la contraseña son obligatorios.",


        //Lista de menu
        Clients: "Clientes",
        Contacts: "Contactos",
        Domains: "Dominios",
        Dashboard: "Inicio",
        Transactions: "Transacciones",
        Prices: "Lista precios",
        Profile: "Perfil",
        Detail: "Detalle",
        Renew: "Renovar",

        //Footer
        Copyright: "Todos los derechos reservados",

        //Dashboard
        Welcome: "Bienvenido",

        BasicDat: "Datos Basicos",
        Address: "Dirección",
        Communication: "Comunicación",
    },
    EN: {
        Email: "Email",
        Password: "Password",
        Login: "Login",
        Remember: "Did you forget your password?",
        CreateAccount: "Do not you have an account yet?",
        EmailPasswordRequired: "The email and password is required.",

        //Lista de menu
        Clients: "Clients",
        Contacts: "Contacts",
        Domains: "Domains",
        Dashboard: "Dashboard",
        Transactions: "Transactions",
        Prices: "Prices list",
        Profile: "Profile",
        Detail: "Detail",
        Renew: "Renew",

        //Footer
        Copyright: "all rights reserved",

        //Dashboard
        Welcome: "Welcome",

        BasicDat: "Basic dat",
        Address: "Address",
        Communication: "Communication",
    }
};

export default language;