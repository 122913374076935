import React, {Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import language from '../../../store/helpers/language';
import {CircularProgress, LinearProgress, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputLocal from "../../local/InputLocal";
import withContactsNew from "../../../providers/withContactsNew";

const docs = [
    {value: "CO", name: "Colombia"},
];

const styles = {
    welcome: {
        color: "#05092C",
    },

    title: {
        fontSize: "18px",
        fontWeight: "bold",
        lineHeight: "1",
    },
};

const NewContact = (props) => {
    const {classes, handleInputChange, contactNew} = props;
    return (
        <Fragment>
            {props.loadingPage ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LinearProgress/>
                    </Grid>
                </Grid>
                :
                <Grid container spacing={2}>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Card>
                            <CardHeader
                                disableTypography={true}
                                className={classes.title}
                                title={language[props.language].BasicDat}
                            />
                            <Divider light={true}/>
                            <CardContent>
                                <form onSubmit={props.handleSubmit} autoComplete={"off"}>
                                    <Grid container spacing={1}>
                                        <InputLocal
                                            type={"text"}
                                            name={"honorificPrefix"}
                                            id={"honorificPrefix"}
                                            label={"Titulo"}
                                            required={false}
                                            placeholder={"Titulo"}
                                            handleChange={handleInputChange}
                                            value={contactNew.honorificPrefix || ""}
                                        />
                                        <InputLocal
                                            type={"text"}
                                            name={"givenName"}
                                            id={"givenName"}
                                            label={"Nombres"}
                                            required={true}
                                            placeholder={"Nombre del contacto"}
                                            handleChange={handleInputChange}
                                            error={props.error.givenName !== undefined}
                                            value={contactNew.givenName || ""}
                                        />
                                        <InputLocal
                                            type={"text"}
                                            name={"familyName"}
                                            id={"familyName"}
                                            label={"Apelidos"}
                                            required={true}
                                            placeholder={"Apellido del contacto"}
                                            handleChange={handleInputChange}
                                            error={props.error.familyName !== undefined}
                                            value={contactNew.familyName || ""}
                                        />
                                        <InputLocal
                                            type={"text"}
                                            name={"worksFor"}
                                            id={"worksFor"}
                                            label={"Organización"}
                                            required={false}
                                            placeholder={"Organización"}
                                            handleChange={handleInputChange}
                                            value={contactNew.worksFor || ""}
                                        />
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Card>
                            <CardHeader
                                disableTypography={true}
                                className={classes.title}
                                title={language[props.language].Address}
                            />
                            <Divider light={true}/>
                            <CardContent>
                                <form onSubmit={props.handleSubmit} autoComplete={"off"}>
                                    <Grid container spacing={1}>
                                        <InputLocal
                                            options={docs}
                                            type={"select"}
                                            name={"addressCountry"}
                                            id={"addressCountry"}
                                            label={"Pais"}
                                            placeholder={"Pais"}
                                            handleChange={handleInputChange}
                                            value={contactNew.addressCountry || ""}
                                        />
                                        {/*<InputLocal*/}
                                        {/*    type={"text"}*/}
                                        {/*    name={"codArea"}*/}
                                        {/*    id={"codArea"}*/}
                                        {/*    label={"Código de area"}*/}
                                        {/*    required={true}*/}
                                        {/*    placeholder={"Código de area"}*/}
                                        {/*    handleChange={handleInputChange}*/}
                                        {/*/>*/}
                                        {/*<InputLocal*/}
                                        {/*    type={"text"}*/}
                                        {/*    name={"codPostal"}*/}
                                        {/*    id={"codPostal"}*/}
                                        {/*    label={"Código postal"}*/}
                                        {/*    required={true}*/}
                                        {/*    placeholder={"Código postal"}*/}
                                        {/*    handleChange={handleInputChange}*/}
                                        {/*/>*/}
                                        <InputLocal
                                            type={"text"}
                                            name={"addressLocality"}
                                            id={"addressLocality"}
                                            label={"Ciudad"}
                                            required={false}
                                            placeholder={"Ciudad"}
                                            handleChange={handleInputChange}
                                            value={contactNew.addressLocality || ""}
                                        />
                                        <InputLocal
                                            type={"text"}
                                            name={"streetAddress"}
                                            id={"streetAddress"}
                                            label={"Dirección"}
                                            required={true}
                                            placeholder={"Dirección"}
                                            handleChange={handleInputChange}
                                            error={props.error.address !== undefined}
                                            value={contactNew.streetAddress || ""}
                                        />
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Card>
                            <CardHeader
                                disableTypography={true}
                                className={classes.title}
                                title={language[props.language].Communication}
                            />
                            <Divider light={true}/>
                            <CardContent>
                                <form onSubmit={props.handleSubmit} autoComplete={"off"}>
                                    <Grid container spacing={1}>
                                        <InputLocal
                                            type={"text"}
                                            name={"telephone"}
                                            id={"telephone"}
                                            label={"Telefono"}
                                            required={true}
                                            placeholder={"Telefono"}
                                            handleChange={handleInputChange}
                                            error={props.error.telephone !== undefined}
                                            value={contactNew.telephone || ""}
                                        />
                                        <InputLocal
                                            type={"text"}
                                            name={"faxNumber"}
                                            id={"faxNumber"}
                                            label={"Fax"}
                                            required={false}
                                            placeholder={"Fax"}
                                            handleChange={handleInputChange}
                                            value={contactNew.faxNumber || ""}
                                        />
                                        <InputLocal
                                            type={"email"}
                                            name={"email"}
                                            id={"email"}
                                            error={props.error.email !== undefined}
                                            errorMessage={props.error.email}
                                            label={"Correo electrónico"}
                                            required={true}
                                            placeholder={"Correo electrónico"}
                                            handleChange={handleInputChange}
                                            value={contactNew.email || ""}
                                        />

                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            color="primary"
                            className={classes.submit}
                            onClick={(e) => props.handleSubmit(e)}
                        >
                            {props.loading ?
                                <CircularProgress
                                    size={24}
                                    color={"secondary"}/>
                                : "Guardar"
                            }
                        </Button>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Button
                            type="button"
                            variant="outlined"
                            fullWidth
                            color="inherit"
                            className={classes.submit}
                            onClick={() => props.history.push("/Contacts")}
                            disabled={props.loading}
                        >
                            Cancelar
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                    </Grid>
                </Grid>
            }
        </Fragment>
    )
};

export default withContactsNew(withStyles(styles)(NewContact));