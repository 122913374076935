import
{
    CHANGE_MOD_PROD, userConstants, CHANGE_LANGUAGE, CHANGE_CURRENCY, CHANGE_ORDER, CHANGE_ORDER_ITEM
} from './../actions/nameActions';

let userActive = JSON.parse(localStorage.getItem('user'));
let order = JSON.parse(localStorage.getItem('order'));
let orderItems = order !== null ? order.items.length : 0;
export const initialState = {
    integrationPhase: false,
    toastDashboard: {
        message: '',
        typeMessage: '',
        status: false
    },
    userActive,
    language: "ES",
    currency: "USD",
    order,
    orderItems,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_MOD_PROD:///Este va a ser el reducer para obtener el nuevo estado del entorno.
            return (
                Object.assign({}, state, {
                    integrationPhase: action.value
                })
            );
        case CHANGE_LANGUAGE:
            return (
                Object.assign({}, state, {
                    language: action.value,
                })
            );
        case CHANGE_CURRENCY:
            return (
                Object.assign({}, state, {
                    currency: action.value,
                })
            );
        case CHANGE_ORDER:
            return (
                Object.assign({}, state, {
                    order: action.value,
                })
            );
        case CHANGE_ORDER_ITEM:
            return (
                Object.assign({}, state, {
                    orderItems: action.value,
                })
            );
        case userConstants.LOGIN_SUCCESS:
            return (
                Object.assign({}, state, {
                    userActive: action.user,
                })
            );
        default:
            return (state)
    }
};