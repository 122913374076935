import {CHANGE_CLIENT, CHANGE_CLIENT_INPUT} from "../actions/nameActions";

const initialState = {
    client: {
        id: "",
        username: "",
        email: "",
        password: "",
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_CLIENT:
            return ({...state, client: action.value});
        case CHANGE_CLIENT_INPUT:
            return ({...state, [action.name]: action.value});
        default:
            return ({...state})
    }
};