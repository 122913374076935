import React, {Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {LinearProgress, AppBar, CircularProgress, Slide} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Dns, Security, Contacts, NoEncryption, DnsOutlined, Refresh, Search} from '@material-ui/icons';
import InputLocal from "../../local/InputLocal";
import withDomainsDetail from "../../../providers/withDomainsDetail";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InputLocalFull from "../../local/InputLocalFull";
import TableLocal from "../../local/TableLocal";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Fab
} from "@material-ui/core";
import {
    Edit,
    Delete,
    Add
} from '@material-ui/icons';

const cName = [
    {value: "A", name: "A"},
    {value: "CNAME", name: "CNAME"},
    {value: "MX", name: "MX"},
    {value: "TXT", name: "TXT"},
];

const Ttl = [
    {value: 0, name: "Automatic TTL"},
    {value: 120, name: "2 Minutos"},
    {value: 300, name: "5 Minutos"},
    {value: 600, name: "10 Minutos"},
    {value: 900, name: "15 Minutos"},
    {value: 1800, name: "30 Minutos"},
    {value: 3600, name: "1 Hora"},
    {value: 7200, name: "2 Hora"},
    {value: 18000, name: "5 Hora"},
    {value: 43200, name: "12 Hora"},
    {value: 86400, name: "1 Dia"},
];

const Prefix = [
    {value: "Sr", name: "Sr"},
    {value: "Sra", name: "Sra"},
];

const DocumentType = [
    {value: "", name: "Ninguno"},
    {value: "Cc", name: "Cedula de ciudadania"},
    {value: "Ce", name: "Cedula de extranjeria"},
    {value: "Ps", name: "Pasaporte"},
];

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

const TabContainer = (props) => {
    return (
        <Fragment>
            <CardHeader
                title={props.title}
            />
            <CardContent>
                <Typography component="div" style={{padding: 8 * 3}}>
                    {props.children}
                </Typography>
            </CardContent>
        </Fragment>
    );
};


const validarTypeDns = (typeDns, dnsValue, changeNewDns, errorIp, errorValue) => {
    if (typeDns === "A") {
        return (
            <InputLocalFull
                type={"text"}
                // options={cName}
                name={"dnsValue"}
                value={dnsValue}
                id={"ipv4"}
                label={"IPV4 Address"}
                placeholder={"IPV4 Address"}
                handleChange={(e) => changeNewDns(e)}
                error={errorIp !== undefined}
                helperText={errorIp}
                required={true}
            />
        )
    } else if (typeDns === "CNAME") {
        return (
            <InputLocalFull
                type={"text"}
                // options={cName}
                name={"dnsValue"}
                value={dnsValue}
                id={"ipv4"}
                label={"Nombre Dominio"}
                placeholder={"Nombre Dominio"}
                handleChange={(e) => changeNewDns(e)}
                error={errorValue !== undefined}
                helperText={errorValue}
                required={true}
            />
        )
    } else if (typeDns === "MX") {
        return (
            <InputLocalFull
                type={"text"}
                // options={cName}
                name={"dnsValue"}
                value={dnsValue}
                id={"ipv4"}
                label={"Servidor"}
                placeholder={"Servidor"}
                handleChange={(e) => changeNewDns(e)}
                error={errorValue !== undefined}
                helperText={errorValue}
                required={true}
            />
        )
    } else if (typeDns === "TXT") {
        return (
            <InputLocalFull
                type={"text"}
                // options={cName}
                name={"dnsValue"}
                value={dnsValue}
                id={"ipv4"}
                label={"Texto"}
                placeholder={"Texto"}
                handleChange={(e) => changeNewDns(e)}
                error={errorValue !== undefined}
                helperText={errorValue}
                required={true}
            />
        )
    }
}

const validarTypeDnsEdit = (typeDns, value, changeOldDns, errorIp, errorValue) => {
    if (typeDns === "A") {
        return (
            <InputLocalFull
                type={"text"}
                // options={cName}
                name={"value"}
                value={value}
                id={"ipv4"}
                label={"IPV4 Address"}
                placeholder={"IPV4 Address"}
                handleChange={(e) => changeOldDns(e)}
                error={errorIp !== undefined}
                helperText={errorIp}
                required={true}
            />
        )
    } else if (typeDns === "CNAME") {
        return (
            <InputLocalFull
                type={"text"}
                // options={cName}
                name={"value"}
                value={value}
                id={"ipv4"}
                label={"Nombre Dominio"}
                placeholder={"Nombre Dominio"}
                handleChange={(e) => changeOldDns(e)}
                error={errorValue !== undefined}
                helperText={errorValue}
                required={true}
            />
        )
    } else if (typeDns === "MX") {
        return (
            <InputLocalFull
                type={"text"}
                // options={cName}
                name={"value"}
                value={value}
                id={"ipv4"}
                label={"Servidor"}
                placeholder={"Servidor"}
                handleChange={(e) => changeOldDns(e)}
                error={errorValue !== undefined}
                helperText={errorValue}
                required={true}
            />
        )
    } else if (typeDns === "TXT") {
        return (
            <InputLocalFull
                type={"text"}
                // options={cName}
                name={"value"}
                value={value}
                id={"ipv4"}
                label={"Texto"}
                placeholder={"Texto"}
                handleChange={(e) => changeOldDns(e)}
                error={errorValue !== undefined}
                helperText={errorValue}
                required={true}
            />
        )
    }
}

const ModalContact = (props) => {
    return (
        <Dialog open={props.modal} TransitionComponent={Transition} fullWidth maxWidth={"md"}>
            <DialogTitle>{props.idEditContact === 0 ? "Agregar Contacto" : "Editar contacto"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item lg={2} md={2} sm={2} xs={12}>
                        <InputLocalFull
                            type={"select"}
                            value={props.editContact.honorificPrefix}
                            options={Prefix}
                            name={"honorificPrefix"}
                            id={"Honorifico"}
                            label={"Honorifico"}
                            handleChange={(e) => props.changeContactDomain(e)}
                            optionNull={false}
                        />
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                        <InputLocalFull
                            type={"text"}
                            value={props.editContact.givenName}
                            name={"givenName"}
                            id={"nombres"}
                            label={"Nombres *"}
                            handleChange={(e) => props.changeContactDomain(e)}
                            error={props.error.editContact.givenName !== undefined}
                            helperText={props.error.editContact.givenName}
                        />
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                        <InputLocalFull
                            type={"text"}
                            value={props.editContact.familyName}
                            name={"familyName"}
                            id={"apellidos"}
                            label={"Apellidos *"}
                            handleChange={(e) => props.changeContactDomain(e)}
                            error={props.error.editContact.familyName !== undefined}
                            helperText={props.error.editContact.familyName}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <InputLocalFull
                            type={"select"}
                            value={props.editContact.documentType}
                            options={DocumentType}
                            name={"documentType"}
                            id={"Tipo de documento"}
                            label={"Tipo de documento"}
                            handleChange={(e) => props.changeContactDomain(e)}
                            optionNull={false}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <InputLocalFull
                            type={"text"}
                            value={props.editContact.documentNumber}
                            name={"documentNumber"}
                            id={"Numero de documento"}
                            label={"Numero de documento *"}
                            handleChange={(e) => props.changeContactDomain(e)}
                            error={props.error.editContact.documentNumber !== undefined}
                            helperText={props.error.editContact.documentNumber}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <InputLocalFull
                            type={"text"}
                            value={props.editContact.address.addressCountry}
                            name={"addressCountry"}
                            id={"Pais"}
                            label={"Pais *"}
                            handleChange={(e) => props.changeContactDomain(e)}
                            error={props.error.editContact.addressCountry !== undefined}
                            helperText={props.error.editContact.addressCountry}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <InputLocalFull
                            type={"text"}
                            value={props.editContact.address.addressRegion}
                            name={"addressRegion"}
                            id={"Region"}
                            label={"Region *"}
                            handleChange={(e) => props.changeContactDomain(e)}
                            error={props.error.editContact.addressRegion !== undefined}
                            helperText={props.error.editContact.addressRegion}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <InputLocalFull
                            type={"text"}
                            value={props.editContact.address.addressLocality}
                            name={"addressLocality"}
                            id={"Ciudad"}
                            label={"Ciudad *"}
                            handleChange={(e) => props.changeContactDomain(e)}
                            error={props.error.editContact.addressLocality !== undefined}
                            helperText={props.error.editContact.addressLocality}
                        />
                    </Grid>

                    <Grid item lg={8} md={8} sm={8} xs={12}>
                        <InputLocalFull
                            type={"text"}
                            value={props.editContact.address.streetAddress}
                            name={"streetAddress"}
                            id={"Direccion"}
                            label={"Dirección *"}
                            handleChange={(e) => props.changeContactDomain(e)}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <InputLocalFull
                            type={"text"}
                            value={props.editContact.address.postalCode}
                            name={"postalCode"}
                            id={"Codigo postal"}
                            label={"Codigo postal *"}
                            handleChange={(e) => props.changeContactDomain(e)}
                        />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <InputLocalFull
                            type={"text"}
                            value={props.editContact.worksFor}
                            name={"worksFor"}
                            id={"worksFor"}
                            label={"worksFor"}
                            handleChange={(e) => props.changeContactDomain(e)}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <InputLocalFull
                            type={"text"}
                            value={props.editContact.telephone}
                            name={"telephone"}
                            id={"Telefono"}
                            label={"Telefono"}
                            handleChange={(e) => props.changeContactDomain(e)}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <InputLocalFull
                            type={"text"}
                            value={props.editContact.mobilephone}
                            name={"mobilephone"}
                            id={"Celular"}
                            label={"Celular"}
                            handleChange={(e) => props.changeContactDomain(e)}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <InputLocalFull
                            type={"text"}
                            value={props.editContact.faxNumber}
                            name={"faxNumber"}
                            id={"Fax"}
                            label={"Fax"}
                            handleChange={(e) => props.changeContactDomain(e)}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <InputLocalFull
                            type={"text"}
                            value={props.editContact.email}
                            name={"email"}
                            id={"Correo"}
                            label={"Correo *"}
                            handleChange={(e) => props.changeContactDomain(e)}
                            error={props.error.editContact.email !== undefined}
                            helperText={props.error.editContact.email}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <InputLocalFull
                            type={"text"}
                            value={props.editContact.additionalEmail}
                            name={"additionalEmail"}
                            id={"Otro correo"}
                            label={"Otro correo"}
                            handleChange={(e) => props.changeContactDomain(e)}
                            error={props.error.editContact.additionalEmail !== undefined}
                            helperText={props.error.editContact.additionalEmail}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="Secundary"
                    className={props.classes.submit}
                    onClick={() => props.openCloseModal()}

                >
                    Cerrar
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    fullWidth
                    color="primary"
                    className={props.classes.submit}
                    disabled={
                        !(
                            props.editContact.givenName !== "" &&
                            props.editContact.familyName !== "" &&
                            props.editContact.email !== "" &&
                            props.editContact.documentType !== "" &&
                            props.editContact.documentNumber !== ""
                        )}
                    onClick={
                        props.dataEdit.givenName !== "" &&
                        props.dataEdit.familyName !== "" &&
                        props.dataEdit.email !== "" &&
                        props.dataEdit.documentType !== "" &&
                        props.dataEdit.documentNumber !== ""
                            ? () => props.sendEditContact()
                            : ""
                    }
                >
                    {props.loadingButtonEdit ?
                        <CircularProgress
                            size={24}
                            color={"secondary"}/>
                        : props.idEditContact === 0 ? "Agregar" : "Editar"
                    }
                </Button>

            </DialogActions>
        </Dialog>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DetailDomain = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const {rows, headRows, rowsList} = props;


    function handleChange(event, newValue) {
        setValue(newValue);
    }


    return (
        <Fragment>
            {props.loading ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LinearProgress/>
                    </Grid>
                </Grid>
                :
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant="fullWidth"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    xs={12}
                                >
                                    <Tab label="Cambio de DNS" icon={<Dns/>}/>
                                    <Tab label="Codigo de autorización" icon={<Security/>}/>
                                    <Tab label="Gestion de contactos" icon={<Contacts/>}/>
                                    <Tab label="Desactivar privacidad" icon={<NoEncryption/>}/>
                                    <Tab label="Admin. zona dns" icon={<DnsOutlined/>}/>
                                </Tabs>
                            </AppBar>
                            {value === 0 &&
                            <TabContainer title={"Servidores DNS:"}>
                                <Grid container spacing={2} alignItems="center">
                                    {props.nameServers !== null && (
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            {props.nameServers.map((name, key) =>
                                                <InputLocalFull
                                                    value={name.name}
                                                    type={"text"}
                                                    name={`servidor${key + 1}`}
                                                    id={`servidor${key + 1}`}
                                                    label={`servidor ${key + 1}`}
                                                    placeholder={`servidor ${key + 1}`}
                                                    handleChange={(e) => props.handleChangeNameServer(key, e.target.value)}
                                                />
                                            )}
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <InputLocalFull
                                            value={props.newNameServer}
                                            type={"text"}
                                            name={"newNameServer"}
                                            id={"newNameServer"}
                                            label={"Nuevo servidor dns"}
                                            placeholder={"Nuevo servidor dns"}
                                            handleChange={(e) => props.handleInputChange(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            color="primary"
                                            disabled={!(props.newNameServer !== "")}
                                            onClick={() => props.newNameServer !== "" ? props.updateDnsServer() : ""}
                                        >
                                            {props.loadingButton ?
                                                <CircularProgress
                                                    size={24}
                                                    color={"secondary"}/>
                                                : "Guardar"
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                            </TabContainer>}
                            {value === 1 &&
                            <TabContainer title={"Codigo autorización:"}>
                                <InputLocal
                                    type={"text"}
                                    name={"codAutorization"}
                                    id={"codAutorization"}
                                    label={"Codigo de autorización"}
                                    placeholder={"Codigo de autorización"}
                                    handleChange={(e) => console.log(e)}
                                />
                            </TabContainer>}
                            {value === 2 &&
                            <TabContainer title={"Contactos:"}>
                                {props.contactManagament.length > 0 ? props.contactManagament.map((data, key) => (
                                    <ExpansionPanel key={key}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography variant={"h5"}>{data.title}</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container spacing={2}>
                                                {data.data && Object.keys(data.data).length > 0
                                                    ? data.data.map((contact, key2) => (
                                                        <Grid item xs={12} key={"key2 " + key2}>
                                                            <Typography variant={"h6"}>{contact.title}</Typography>
                                                            <Typography>{contact.value}</Typography>
                                                            <Divider light={true}/>
                                                        </Grid>
                                                    ))
                                                    : data.add &&
                                                    <Tooltip title={"Agregar"}>
                                                        <Fab
                                                            onClick={() => props.editContactFunction(1, data.title)}
                                                            size="small"
                                                            style={{
                                                                width: 32,
                                                                height: 32,
                                                                margin: 5,
                                                            }}
                                                        >
                                                            <Add/>
                                                        </Fab>
                                                    </Tooltip>
                                                }
                                                {data.edit &&
                                                <Tooltip title={"Editar"}>
                                                    <Fab
                                                        onClick={() => props.editContactFunction(key, data.title)}
                                                        size="small"
                                                        style={{
                                                            width: 32,
                                                            height: 32,
                                                            margin: 5,
                                                        }}
                                                    >
                                                        <Edit/>
                                                    </Fab>
                                                </Tooltip>
                                                }
                                                {data.delete &&
                                                <Tooltip title={"Eliminar"}>
                                                    <Fab
                                                        onClick={() => props.deleteContactFunction(key, data.title)}
                                                        size="small"
                                                        style={{
                                                            width: 32,
                                                            height: 32,
                                                            margin: 5,
                                                        }}
                                                    >
                                                        <Delete/>
                                                    </Fab>
                                                </Tooltip>
                                                }
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )) : "Contactos:"}
                                {props.modal && <ModalContact {...props} classes={classes}/>}

                            </TabContainer>}
                            {value === 3 && <TabContainer title={"Privacidad:"}>Privacidad</TabContainer>}
                            {value === 4 && <TabContainer title={"Administrar zona dns:"}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} sm={10} md={10} lg={10}>
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            // props.request()
                                        }}>
                                            <InputLocalFull
                                                type={"text"}
                                                name={"buscar"}
                                                id={"buscar"}
                                                label={"Buscar"}
                                                value={props.search}
                                                placeholder={"Buscar"}
                                                handleChange={(e) => props.filter(e)}
                                                startAdornment={<Search className={classes.block} color="inherit"/>}
                                            />
                                        </form>
                                    </Grid>
                                    <Grid item xs={12} sm={2} md={2} lg={2}>
                                        <Tooltip title="Actualizar">
                                            <IconButton
                                                onClick={() => props.contactManagement()}
                                            >
                                                <Refresh className={classes.block} color="inherit"/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={2}>
                                        <InputLocalFull
                                            type={"select"}
                                            value={props.dnsType}
                                            options={cName}
                                            name={"dnsType"}
                                            id={"tipo"}
                                            label={"Tipo"}
                                            handleChange={(e) => props.changeNewDns(e)}
                                            optionNull={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={3}>
                                        <InputLocalFull
                                            type={"text"}
                                            // options={cName}
                                            value={props.dnsName}
                                            name={"dnsName"}
                                            id={"name"}
                                            label={"Nombre"}
                                            placeholder={"Nombre"}
                                            handleChange={(e) => props.changeNewDns(e)}
                                            error={props.error.name !== undefined}
                                            helperText={props.error.name}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={3}>
                                        {validarTypeDns(props.dnsType, props.dnsValue, props.changeNewDns, props.error.ip, props.error.value)}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={2}>
                                        <InputLocalFull
                                            type={"select"}
                                            options={Ttl}
                                            value={props.dnsTtl}
                                            name={"dnsTtl"}
                                            id={"ttl"}
                                            label={"TTL"}
                                            handleChange={(e) => props.changeNewDns(e)}
                                            optionNull={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2} lg={2}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            color="primary"
                                            className={classes.submit}
                                            disabled={!(props.dnsName !== "" && props.dnsValue !== "")}
                                            onClick={() => props.dnsName !== "" && props.dnsValue !== "" ? props.addNewDns() : ""}
                                        >
                                            {props.loadingButton ?
                                                <CircularProgress
                                                    size={24}
                                                    color={"secondary"}/>
                                                : "Agregar"
                                            }
                                        </Button>
                                    </Grid>
                                    <Dialog open={props.modal} TransitionComponent={Transition} fullWidth
                                            maxWidth={"md"}>
                                        <DialogTitle>Editar dns</DialogTitle>
                                        <DialogContent>
                                            <InputLocalFull
                                                type={"select"}
                                                value={props.dataEdit.type}
                                                options={cName}
                                                name={"type"}
                                                id={"tipo"}
                                                label={"Tipo"}
                                                // handleChange={(e) => props.changeOldDns(e)}
                                                disabled={true}
                                                optionNull={false}
                                            />
                                            <InputLocalFull
                                                type={"text"}
                                                value={props.dataEdit.name}
                                                name={"name"}
                                                id={"nombre"}
                                                label={"Nombre *"}
                                                handleChange={(e) => props.changeOldDns(e)}
                                                error={props.error.nameEdit !== undefined}
                                                helperText={props.error.nameEdit}
                                            />
                                            {validarTypeDnsEdit(props.dataEdit.type, props.dataEdit.value, props.changeOldDns.bind(this), props.error.ipEdit, props.error.valueEdit)}
                                            <InputLocalFull
                                                type={"select"}
                                                value={props.dataEdit.ttl}
                                                options={Ttl}
                                                name={"ttl"}
                                                id={"ttl"}
                                                label={"Ttl"}
                                                handleChange={(e) => props.changeOldDns(e)}
                                                optionNull={false}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                                color="Secundary"
                                                className={classes.submit}
                                                onClick={() => props.openCloseModal()}

                                            >
                                                Cerrar
                                            </Button>
                                            <Button
                                                type="button"
                                                variant="contained"
                                                fullWidth
                                                color="primary"
                                                className={classes.submit}
                                                disabled={!(props.dataEdit.name !== "" && props.dataEdit.value !== "")}
                                                onClick={props.dataEdit.name !== "" && props.dataEdit.value !== "" ? () => props.updateDnsRequest() : ""}
                                            >
                                                {props.loadingButtonEdit ?
                                                    <CircularProgress
                                                        size={24}
                                                        color={"secondary"}/>
                                                    : "Editar"
                                                }
                                            </Button>

                                        </DialogActions>
                                    </Dialog>
                                </Grid>

                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <TableLocal
                                            rowsList={rowsList}
                                            headRows={headRows}
                                            rows={rows}
                                            title={"Listado DNS"}
                                            check={false}
                                            actions={[
                                                {
                                                    "name": "Eliminar",
                                                    "funcion": (id) => props.deletedns(id),
                                                    "value": "id",
                                                    "action": "delete"
                                                },
                                                {
                                                    "name": "Actualizar",
                                                    "funcion": (id) => props.updatedns(id),
                                                    "value": "id",
                                                    "action": "update"
                                                },

                                            ]}
                                            actionButton={true}
                                            filterAvance={false}
                                        />
                                    </Grid>
                                </Grid>

                            </TabContainer>}
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                </Grid>
            }
        </Fragment>
    )
};

export default withDomainsDetail(DetailDomain);