import React, {Fragment} from 'react';
import Header from "./sections/header";
import Footer from "./sections/footer";
import Routes from "./routes";



function AppLanding() {
    return (
        <Fragment>
                <Header/>
                <Routes/>
                <Footer/>
        </Fragment>
    );
}

export default AppLanding;
