import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import TableLocal from "../../local/TableLocal";
import TablePagination from "@material-ui/core/TablePagination";
import {LinearProgress} from "@material-ui/core";
import withDomains from "../../../providers/withDomains";
import {Send} from "@material-ui/icons";
import InputLocalFull from "../../local/InputLocalFull";
import InputLocal from "../../local/InputLocal";

const styles = theme => ({
    paper: {
        maxWidth: 1300,
        margin: 'auto',
        overflow: 'hidden',
        marginBottom: "10px"
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        backgroundColor: "#04BC00",
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '10px 16px',
    },
    fieldsFilter: {
        marginRight: "10%"
    }
});

const docs = [
    {value: "1", name: "Pendiente"},
    {value: "1", name: "Registrado (Activo)"},
    {value: "1", name: "Cancelado"},
    {value: "1", name: "Vencido"},
    {value: "1", name: "Suspendido"},
    {value: "1", name: "RedemptionPeriod"},
    {value: "1", name: "PendingDelete"},
    {value: "1", name: "PendingRestore"},
    {value: "1", name: "Transfer prohibited"},
    {value: "1", name: "ClientHold"},
    {value: "1", name: "ClientUpdateProhibited"},
    {value: "1", name: "ClientDeleteProhibited"},
    {value: "1", name: "ClientRenewProhibited"},
];


function NewDomains(props) {
    const {classes, load, rows, headRows, page, rowsPerPage, count, rowsList, changeFilters} = props;

    const fieldsFilter = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justify="flex-start" spacing={1}>
                        <Grid item>
                            <InputLocalFull
                                options={docs}
                                type={"text"}
                                id="dominio-nombre"
                                label="Dominio"
                                name={"name"}
                                value={props.search.name}
                                placeholder={"Dominio"}
                                onChange={(e) => changeFilters(e)}
                            />
                        </Grid>
                        <Grid item>
                            <InputLocalFull
                                type={"date"}
                                id="fechaDesde"
                                name={"fechaDesde"}
                                value={props.search.name}
                                label={"Fecha desde"}
                                placeholder={"Fecha desde"}
                                onChange={(e) => changeFilters(e)}
                            />
                        </Grid>
                        <Grid item>
                            <InputLocalFull
                                type={"date"}
                                id="fechaHasta"
                                name={"fechaHasta"}
                                value={props.search.name}
                                label={"Fecha hasta"}
                                placeholder={"Fecha hasta"}
                                onChange={(e) => changeFilters(e)}
                            />
                        </Grid>
                        <Grid item>
                            <InputLocalFull
                                options={docs}
                                type={"select"}
                                name={"status"}
                                id={"status"}
                                label={"Estado"}
                                placeholder={"Estado"}
                            />
                        </Grid>
                        <Grid key={"3"} item xs={12} style={{marginTop: "1%"}}>
                            <Button variant="contained" color="primary" onClick={() => props.request()}>
                                {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                                <Send/>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <SearchIcon className={classes.block} color="inherit"/>
                        </Grid>
                        <Grid item xs>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                props.request()
                            }}>
                                <TextField
                                    value={props.search.name}
                                    name={"search"}
                                    fullWidth
                                    placeholder="Buscar"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: classes.searchInput,
                                    }}
                                    onChange={props.handleInputChange}
                                />
                            </form>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" className={classes.addUser}
                                    onClick={() => props.history.push("Domains/0")}>
                                Registrar Dominio
                            </Button>
                            <Tooltip title="Actualizar">
                                <IconButton onClick={() => props.updateSearch()}>
                                    <RefreshIcon className={classes.block} color="inherit"/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.contentWrapper}>
                {load ?
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                            <LinearProgress/>
                        </Grid>
                    </Grid>
                    : <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <TableLocal
                                rowsList={rowsList}
                                headRows={headRows}
                                rows={rows}
                                title={"Listado de dominios"}
                                check={false}
                                openFilter={() => props.openFilter()}
                                filter={props.filter}
                                fieldsFilter={() => fieldsFilter()}
                                actions={[
                                    {
                                        "name": "Administrar dominio",
                                        "funcion": (id) => props.detailDomains(id),
                                        "value": "id",
                                        "action": "administration",
                                        "colorBackground": "default"
                                    },
                                    {
                                        "name": "Renovar dominio",
                                        "funcion": (id) => props.renewDomain(id),
                                        "value": "id",
                                        "action": "renovation",
                                        "colorBackground": "default"
                                    },
                                ]}
                                actionButton={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TablePagination
                                rowsPerPageOptions={[20, 50, 100]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={props.handleChangePage}
                                onChangeRowsPerPage={props.handleChangeRowsPerPage}
                                labelRowsPerPage={"items por pagina"}
                                labelDisplayedRows={({from, to, count}) => `${from}-${to} de ${count} `}
                            />
                        </Grid>
                    </Grid>
                }
            </div>
        </Paper>
    );
}

NewDomains.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withDomains(withStyles(styles)(NewDomains));