import {Component, Fragment} from "react";
import styled from "@emotion/styled";

/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import Switch from "react-switch";
import Select, {components} from 'react-select'
import {country} from './country'

export const FLEX = styled.div` 
    display:flex;
    flex-flow:       ${props => props.column ? "column wrap" : "row wrap"};
    justify-content: ${props => props.jc ? props.jc : "center"};
    align-items    : ${props => props.alg ? props.alg : "center"};
    flex           : ${props => props.flex ? props.flex : "0 0 auto"};
`

export const ELASTIC = styled.div`
   flex:${props => props.flex ? props.flex : "0 0 auto"} 
`

export const COLORS = {
    azul: "#05092C",
    verde: "#04BC00",
    gris: "#F0F0F0",
    negro: "#3E4A58",
    texto: "#6F7881",
    naranja: "#FFAB07",
};

export const BgAzul = css` 
    background-color:${COLORS.azul};
`

export const VERDE = css`
    color:${COLORS.verde};+
    
`

export const AZUL = css`
    color:${COLORS.azul};
`

const Mymask = styled.div`
    background-image:url(img/${props => props.img}.png);
    background-repeat:no-repeat;
    background-position: center ${props => props.position ? props.position : "0"};
    background-size: 100% auto;
    width:100%;
    
    img{
        width:100%;
        height:auto;
        display:block;
        opacity:0;
    }
`

export const Mask = ({img, position, recuadro, className, style}) => {
    return (
        <Mymask className={className} img={img} position={position}>
            <img src={`img/${recuadro}.png`} alt="" style={style}/>
        </Mymask>
    )
}


export const Btn = styled.button`

    background: ${props => props.loading ? COLORS.verde + " url(/img/loading.png) repeat-x center}" : COLORS.verde};
    cursor:pointer;
    border-radius:${props => props.bdr ? props.bdr : "25px"};
    width:100%;
    border:none;
    font-weight:bold;
    color:white;
    padding: 0 .75em;
    min-height:${props => props.hg ? props.hg : "45px"};
    line-height:${props => props.hg ? props.hg : "45px"};
    
    &:focus{
        outline:none;
    }
    
    &:hover{
        background-color: #069003;
    }
    animation: ${props => props.loading ? "loading 1s linear infinite" : "none"};

`
const Campodiv = styled.div`
    postion:relative;
    width:100%;
    
    label{
        width:100%;
        margin:0;
        font-size:14px;
        color: ${props => props.error ? "red" : COLORS.texto}
    }
    
    input,select{
        height:45px;
        background-color:white;
        border-radius:4px;
        width:100%;
        cursor:pointer;
        border:2px solid ${props => props.error ? "red" : "#e4e4e4"};
        padding: 0 .75em;
        
        &:focus{
        outline:none;
        border-color:${COLORS.naranja}
        }
    }
    
     input{
        &:focus{
         background-color:${COLORS.gris};
        }
     }
     
`

const Iconflag = styled.div`
    width:100%;
    padding-right:.75em;
    flex:0 0 40px;
    
    img{
        width:100%;
        height:auto;
        display:block;
        }
`

export class ReactSelectFlag extends Component {
    render() {

        const singleOption = (props) => (
            <components.Option {...props}>
                <FLEX flex={"1 0 100%"}>
                    <Iconflag> {props.data.flagPath ? props.data.flagPath : null}</Iconflag>
                    <ELASTIC flex={"1 0 auto"}>{props.data.label}</ELASTIC>
                </FLEX>
            </components.Option>
        );


        const singleValue = (props) => (
            <components.SingleValue {...props}>
                <FLEX flex={"1 0 100%"} style={{height: "100%"}}>
                    <Iconflag> {props.data.flagPath ? props.data.flagPath : null}</Iconflag>
                    <ELASTIC flex={"1 0 auto"}>{props.data.label}</ELASTIC>
                </FLEX>
            </components.SingleValue>
        );

        const defaulValue = (value) => (
            country.map((objCountry, key) => {
                if (value === objCountry.value) {
                    return objCountry
                }
                return "";
            })
        );

        // const Mycaret = (props) => (
        //     <components.DropdownIndicator {...props}>
        //         <img src="/img/sort_both.png" width={"24px"} alt=""/>
        //     </components.DropdownIndicator>
        // )

        return (
            <Select
                name={this.props.name}
                placeholder={"Buscar"}
                classNamePrefix={"flag-select"}
                options={country}
                defaultValue={defaulValue(this.props.value)}
                onChange={(props) => this.props.onChange(props)}
                components={{
                    Option: singleOption,
                    SingleValue: singleValue,
                    // DropdownIndicator: Mycaret
                }}
                styles={{
                    control: (base, state) => Object.assign({}, base,
                        {
                            marginBottom: 0,
                            minHeight: "35px",
                            boxShadow: "none",
                            border: "2px solid #e4e4e4",
                            borderColor: state.isFocused ? "#FFAB07" : "#e4e4e4",
                        }),
                    DropdownIndicator: (base) => Object.assign({}, base,
                        {
                            padding: "0 .5em",
                            height: "40px"
                        }),

                    indicatorsContainer: (base) => Object.assign({}, base,
                        {
                            height: "40px",
                            padding: "0",
                        }),
                    valueContainer: (base) => Object.assign({}, base,
                        {
                            height: "42px",
                            padding: "0 .75em",
                            overflow: "initial"
                        }),
                    singleValue: (base) => Object.assign({}, base,
                        {
                            height: "40px",
                            padding: "0",
                            lineHeight: "40px"
                        }),
                }}

                isDisabled={this.props.isDisabled}
            />
        )
    }
}


export const Campo = ({
                          className, error = "", type = "text", noLabel = false, name, flag, label, onchange, value, placeholder, select, options = [], styles
                      }) => {
    return (
        <Campodiv error={error !== ""} className={className}>
            {!noLabel &&
            <label>
                {error !== "" ? error : label}
            </label>
            }

            {flag ?
                <ReactSelectFlag value={57} onChange={onchange} name={name}/>
                :
                <Fragment>
                    {select ?
                        <select name={name} onChange={onchange} value={value} id="">
                            {options.map((iten, index) =>
                                <option value={iten.value} key={index}>{iten.name}</option>
                            )}
                        </select> :
                        <input
                            type={type}
                            name={name}
                            value={value}
                            onChange={onchange}
                            placeholder={placeholder}
                            autoComplete={"off"}
                        />
                    }
                </Fragment>}

        </Campodiv>
    )
}

export const Campotel = ({
                             error = "",
                             errorselect = "",
                             label,
                             onchange,
                             onchangeselect,
                             placeholder,
                             value,
                             valueselect,
                             nameAddressCountry,
                             nameAddress,

                         }) => {
    return (
        <Campodiv error={error !== "" || errorselect !== ""}>

            <label>
                {error !== "" ? error : (errorselect !== "" ? errorselect : label)}
            </label>

            <FLEX>
                <div style={{flex: "0 0 180px", padding: "0 1em 0 0"}}>
                    <ReactSelectFlag value={valueselect} onChange={(e) => onchangeselect(e)} name={nameAddressCountry}/>
                </div>
                <ELASTIC flex={"1 0 100px"}>
                    <input
                        type="text"
                        name={nameAddress}
                        value={value}
                        placeholder={placeholder}
                        onChange={(e) => onchange(e)}
                    />
                </ELASTIC>
            </FLEX>
        </Campodiv>
    )
}

export const Simplecard = styled.div`
    background:${props => props.bg ? props.bg : COLORS.gris};
    border-radius:12px;
    width:100%;
    box-shadow:${props => props.shadow ? "0 1px 3px rgba(0,0,0,.3)" : "none"};
    border: ${props => props.brd ? "1px solid #e4e4e4" : "none"};
    position:relative;
    box-shadow: ${props => props.boxs ? "0 1px 8px rgba(0,0,0,0.15)" : "none"}
`

export const Myswitch = ({label, value, change}) => {
    return (
        <div className={"text-center"}>
            <Switch
                height={20}
                width={30}
                checkedIcon={false}
                uncheckedIcon={false}
                offHandleColor={COLORS.gris}
                onHandleColor={COLORS.verde}
                checked={value}
                onChange={() => change()}
            />
            <small className={"d-block"}>{label}</small>
        </div>
    )
}

export const Ft = styled.p`
    color:${props => props.color ? props.color : "initial"};
    font-weight:${props => props.fw ? props.fw : "300"};
    font-size:${props => props.fz ? props.fz : "16px "};
    margin-bottom:0;
    
   
`

export const Fts = styled.span`
    color:${props => props.color ? props.color : "initial"};
    font-weight:${props => props.fw ? props.fw : "300"};
    font-size:${props => props.fz ? props.fz : "16px "};
    text-decoration-line:${props => props.tdl ? props.tdl : "none"};
    cursor:${props => props.cursor ? props.cursor : "text"};
`





