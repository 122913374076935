import {Component, useRef, Fragment} from "react";
import ReactDOM from "react-dom";
import styled from "@emotion/styled";
import {FLEX, ELASTIC, Btn} from "../ui";
import Myselect, {CurrencySelect} from "../sections/header/myselect";
import {useTransition, useChain, animated, useTrail} from 'react-spring'

/** @jsx jsx */
import {css, jsx} from "@emotion/core";

import {rutas} from "../sections/header";
import {Link} from "react-router-dom";
import withGlobal from "../../providers/withGlobal";

const menumovillink = css`
font-size:1.5em;
color:white;
font-weight:bold;
`

const contLink = css`
width:100%;
`

const modalCont = css` 
    position:absolute;
    width:100vw;
    height:100vh;
    background: rgba(0,0,0,.9);
    z-index:100;
    overflow:hidden;
    overflow-y:auto;
`

const Line = styled.div`
    width:100%;
    height:0;
    border-top: ${props => props.borde ? props.borde : "1px solid white"}    
`

const ModalComp = ({open, set, history,userActive}) => {

    // const [open, set] = useState(false);
    const config = {mass: 5, tension: 2000, friction: 130}


    const springRef = useRef();
    const transitions = useTransition(open, null, {
        from: {position: 'absolute', opacity: 0},
        enter: {opacity: 1},
        leave: {opacity: 0},
        ref: springRef
    })


    const transRef = useRef();
    const trail = useTrail(rutas.length, {
        config,
        opacity: open ? 1 : 0,
        x: open ? 0 : 50,
        from: {opacity: 0, x: 50},
        ref: transRef
    })

    useChain(open ? [springRef, transRef] : [transRef, springRef], [0, open ? 0.1 : .5])


    return (
        <Fragment>
            {transitions.map(({item, key, props}) => item &&
                <animated.div key={key} css={modalCont} style={props} onClick={() => set(!open)}>
                    <FLEX column className="container " alg={"flex-start"}>
                        <FLEX className={"py-3 col-12 px-0"} jc={"flex-start"}>
                            <ELASTIC flex={"0 0 120px"} className={"py-2"}><CurrencySelect/></ELASTIC>
                            <ELASTIC flex={"0 0 120px"} className={"py-2 px-0"}><Myselect/></ELASTIC>
                            <Line className={"mb-3 col-12"}/>
                            <Btn onClick={() => history.push(userActive !== null ? "/Dashboard" : "/login")}
                                 className="col-12 py-2">
                                {userActive !== null ? userActive.user.username : "Ingresar"}
                            </Btn>
                        </FLEX>
                        <Line className={"mb-3"}/>
                        {trail.map(({x, height, ...rest}, index) => (
                            <animated.div
                                key={rutas[index].texto}
                                style={{...rest, transform: x.interpolate(x => `translate3d(${x}%,0,0)`)}}>
                                <animated.div css={contLink} style={{height}}>
                                    <Link to={rutas[index].url} className={"py-2 col-12 d-block mb-2 "}
                                          css={menumovillink}>{rutas[index].texto}</Link>
                                </animated.div>
                            </animated.div>
                        ))}
                    </FLEX>
                </animated.div>)}
        </Fragment>

    )

}

class ShowModalComp extends Component {


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.open !== this.props.open) {

        }
    }


    render() {
        return ReactDOM.createPortal(
            <ModalComp {...this.props}>
                {this.props.children}
            </ModalComp>,
            document.getElementById('cont-modal')
        );
    }
};

export default withGlobal(ShowModalComp);
