import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import FilterListIcon from '@material-ui/icons/FilterList';
import {lighten} from '@material-ui/core/styles/colorManipulator';
import {Dropdown} from 'react-bootstrap';
import {Button, Collapse, Fab} from '@material-ui/core';
import {Info, Edit, Build, Update} from '@material-ui/icons';
import {Link} from 'react-router-dom'

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


function EnhancedTableHead(props) {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headRows, actions, check} = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {check && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount && numSelected > 0}
                            onChange={onSelectAllClick}
                            inputProps={{'aria-label': 'Select all desserts'}}
                        />
                    </TableCell>
                )}
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'center'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                        hidden={!row.visible}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {actions && actions.length > 0 && (
                    <TableCell padding="default" align={"center"}>
                        Acciones
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
}));

const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const {numSelected, title, filterAvance = true} = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected}
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle">
                        {title}
                    </Typography>
                )}
            </div>
            <div className={classes.spacer}/>
            <div className={classes.actions}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete">
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                ) : (
                    filterAvance &&
                    <Tooltip title="Filtro avanzado">
                        <Button onClick={() => props.openFilter ? props.openFilter() : null}>
                            <IconButton aria-label="Filtro avanzado">
                                <FilterListIcon/>
                            </IconButton>
                        </Button>
                    </Tooltip>
                )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}));

function TableLocal({
                        rows, headRows, title, rowsList, actions, check, openFilter, filter = false, fieldsFilter = () => {
        return ""
    }, filterAvance = true, actionButton = false,
                    }) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(rowsList[0]);
    const [selected, setSelected] = React.useState([]);


    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {

            const newSelecteds = rows.map(n => n[rowsList[0]]);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleClick(event, name) {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    function actionsIcon(action) {
        if(action==="delete"){
            return <DeleteIcon/>
        }
        else if(action==="update"){
            return <Edit/>
        }else if(action==="administration"){
            return <Build style={{color:"black"}}/>
        }
        else if(action==="renovation"){
            return <Update/>
        }
        else{
            return <Info/>
        }
    }

    const isSelected = name => selected.indexOf(name) !== -1;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar numSelected={selected.length} title={title}
                                      openFilter={() => openFilter ? openFilter() : null} filterAvance={filterAvance}/>
                <Collapse in={filter} style={{
                    margin: "2%"
                }}>
                    {fieldsFilter()}
                </Collapse>
                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'small'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headRows={headRows}
                            actions={actions}
                            check={check}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, index) => {
                                    const id = row[rowsList[0]];
                                    const promo = row.hasPromo;
                                    const createPromoValue = row.createPromoValue;
                                    const renewPromoValue = row.renewPromoValue;
                                    const restorePromoValue = row.restorePromoValue;
                                    const transferPromoValue = row.transferPromoValue;


                                    const isItemSelected = isSelected(id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                        >
                                            {check && (
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onClick={event => handleClick(event, id)}
                                                        checked={isItemSelected}
                                                        inputProps={{'aria-labelledby': labelId}}
                                                    />
                                                </TableCell>
                                            )}
                                            {rowsList.map((value, key) => {
                                                const head = headRows.find((head => head.id === value));
                                                const link = head.link;
                                                const urlLink = head.urlLink;
                                                if (key === 0) {
                                                    return (
                                                        <TableCell key={key} component="th" id={labelId} scope="row"
                                                                   align={head.numeric ? "right" : "left"}
                                                                   padding="none">
                                                            {promo ?
                                                                <React.Fragment>
                                                                    {row[value]}
                                                                    <img src={"img//promo.png"}
                                                                         style={{maxWidth: "70px"}} alt={"Promo"}/>
                                                                </React.Fragment>
                                                                :
                                                                row[value]
                                                            }
                                                        </TableCell>
                                                    )
                                                } else if (head.visible) {
                                                    return (
                                                        <TableCell key={key}
                                                                   align={head.numeric ? "right" : "left"}>
                                                            {promo ?
                                                                <React.Fragment>
                                                                    {key === 1 && createPromoValue > 0 ?
                                                                        <Tooltip
                                                                            title={`Desde: ${row.promoFrom} - Hasta: ${row.promoTo}`}>
                                                                            <Button>
                                                                                <strike>{row[value]}</strike>
                                                                                ${createPromoValue}
                                                                                <InfoIcon/>
                                                                            </Button>
                                                                        </Tooltip>
                                                                        :
                                                                        key === 1 ? row[value] : ""
                                                                    }
                                                                    {key === 2 && renewPromoValue > 0 ?
                                                                        <Tooltip
                                                                            title={`Desde: ${row.promoFrom} - Hasta: ${row.promoTo}`}>
                                                                            <Button>
                                                                                <strike>{row[value]}</strike>
                                                                                ${renewPromoValue}
                                                                                <InfoIcon/>
                                                                            </Button>
                                                                        </Tooltip>
                                                                        :
                                                                        key === 2 ? row[value] : ""
                                                                    }
                                                                    {key === 3 && transferPromoValue > 0 ?
                                                                        <Tooltip
                                                                            title={`Desde: ${row.promoFrom} - Hasta: ${row.promoTo}`}>
                                                                            <Button>
                                                                                <strike>{row[value]}</strike>
                                                                                ${transferPromoValue}
                                                                                <InfoIcon/>
                                                                            </Button>
                                                                        </Tooltip>
                                                                        :
                                                                        key === 3 ? row[value] : ""
                                                                    }
                                                                    {key === 4 && restorePromoValue > 0 ?
                                                                        <Tooltip
                                                                            title={`Desde: ${row.promoFrom} - Hasta: ${row.promoTo}`}>
                                                                            <Button>
                                                                                <strike>{row[value]}</strike>
                                                                                ${restorePromoValue}
                                                                                <InfoIcon/>
                                                                            </Button>
                                                                        </Tooltip>
                                                                        :
                                                                        key === 4 ? row[value] : ""
                                                                    }
                                                                </React.Fragment>
                                                                :
                                                                link
                                                                    ?
                                                                    <Link to={`${urlLink}/${row.id}`}>
                                                                        {row[value]}
                                                                    </Link>
                                                                    :
                                                                row[value]
                                                            }
                                                        </TableCell>
                                                    )
                                                }
                                                return "";
                                            })}

                                            {actions && !actionButton && actions.length > 0 ? (
                                                <TableCell padding="none" align={"center"}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="ligth"
                                                                         id={`drowdown-baseic-${index}`}>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {actions.map((action, keyAction) => (
                                                                <Dropdown.Item key={keyAction}
                                                                               onClick={() => action.funcion(row[action.value])}>{action.name}</Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </TableCell>
                                            ):actionButton && (
                                                <TableCell padding="none" align={"center"}>
                                                    {actions.map((action, keyAction) => (
                                                        <Tooltip title={action.name}>
                                                            <Fab
                                                                padding="1em"
                                                                key={keyAction}
                                                                onClick={() => action.funcion(row[action.value])}
                                                                size="small"
                                                                color={
                                                                    action.action
                                                                    ?(
                                                                        action.colorBackground
                                                                            ?action.colorBackground
                                                                            :action.action==="delete"
                                                                                ?"secondary"
                                                                                :"primary"
                                                                    )
                                                                    :"default"
                                                                }
                                                                style={{
                                                                    width: 32,
                                                                    height: 32,
                                                                    margin: 5
                                                                }}
                                                            >

                                                                {action.action
                                                                    ? (actionsIcon(action.action))
                                                                    : <Info/>}
                                                            </Fab>
                                                        </Tooltip>
                                                    ))}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            {rows.length <= 0 && (
                                <TableRow style={{height: 49}}>
                                    <TableCell colSpan={3}>Sin registros</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        </div>
    );
}

export default TableLocal;