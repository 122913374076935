import React,{Fragment} from "react";
import styled from "@emotion/styled";
import {COLORS, FLEX, ELASTIC, Mask} from "../../ui";
import {cb} from "../header/myselect";
import {Link,withRouter} from "react-router-dom";


const Myfooter = styled.footer`
    width:100%;
    background:${COLORS.azul};
`

const Franquicia = styled.img`  
    margin:.75em 1em 0 0;
    display:inline-block;
    max-height:25px;
    width:auto;

`

const Titlep = styled.p` 
    font-weight:bold;
    color:white!important;
`

const EnlaceV = styled.span`
    color:${COLORS.verde}!important;
    font-size:14px;
    cursor:pointer;
`

export const TextoB = styled.span`
    color:white!important;
    font-size:${props => props.size ? props.size : "16px"}
`



const Footer = (props) => {

    const ruta = props.history.location.pathname;


    return (
    <Fragment>
       {(ruta !== "/registro" && ruta !== "/login" )  &&
        <Myfooter>
            <FLEX alg={"flex-start"} className="container py-3 px-md-0">
                <div className="col-12 col-md-6 col-lg-5 px-0">
                    <Link to={"/"} className="col-6 col-sm-3 d-block col-md-4 px-0 ">
                        <Mask img={"logoL"} position={"100%"} recuadro={"recuadro"}/>
                    </Link>
                    <div className="col-12 px-0 pt-3 pb-4 pr-md-3" css={cb}>
                        <small className={"d-blobk"} >ResellGO.com es la empresa que se necesita para ser distribuidor de dominios de la forma más
                            fácil, ágil y con una excelente plataforma marca blanca para venta de los dominios, modelo
                            de negocio apto para diseñadores web, empresas de alojamiento web, distribuidores de
                            dominios, empresas de software, etc.</small>
                    </div>
                </div>
                <FLEX className={"col-12 col-md-6 col-lg-7 px-0"} alg={"flex-start"}>
                    <FLEX className={"col-12 col-lg-6 px-0"} alg={"flex-start"}>
                        <FLEX className={"col-6 px-0"} jc={"flex-start"}>
                            <Titlep className={"col-12 px-0 mb-2"}>Contacto</Titlep>
                            <FLEX jc={"flex-start"}>
                                <ELASTIC className={"mr-1"}>
                                    <Mask img={"redes"} position={"75%"} recuadro={"cuadro"}/>
                                </ELASTIC>
                                <small>+57 555 555 55555</small>
                            </FLEX>
                            <FLEX jc={"flex-start"}>
                                <ELASTIC className={"mr-1"}>
                                    <Mask img={"redes"} position={"100%"} recuadro={"cuadro"}/>
                                </ELASTIC>
                                <small>info@resellgo.com</small>
                            </FLEX>
                        </FLEX>
                        <FLEX className={"col-6"} jc={"flex-start"}>
                            <Titlep className={"col-12 px-0 mb-2"} css={cb}> Síguenos</Titlep>
                            <ELASTIC>
                                <Mask img={"redes"} recuadro={"cuadro"} position={"0"}/>
                            </ELASTIC>
                            <ELASTIC>
                                <Mask img={"redes"} recuadro={"cuadro"} position={"50%"}/>
                            </ELASTIC>
                            <ELASTIC>
                                <Mask img={"redes"} recuadro={"cuadro"} position={"25%"}/>
                            </ELASTIC>
                        </FLEX>
                    </FLEX>
                    <FLEX className={"col-12 col-lg-6 px-0 pt-3 pt-lg-0"} alg={"flex-start"}>
                        <FLEX className={"col-6 px-0"} jc={"flex-start"}>
                            <Titlep className={"col-12 px-0 mb-2"} css={cb}>Ayuda</Titlep>
                            <div className="col-12 px-0"><Link to={"/contacto"}><EnlaceV className={"col-12 px-0"}>Contacto</EnlaceV></Link></div>
                            <div className="col-12 px-0"><Link to={"/formas_de_pago"}><EnlaceV className={"col-12 px-0"}>Formas de pago</EnlaceV></Link></div>
                        </FLEX>
                        <FLEX className={"col-6 px-0"} jc={"flex-start"}>
                            <Titlep className={"col-12 px-0 mb-2"} css={cb}>Legal</Titlep>
                            <Link to={"/terminos_y_condiciones"} className={"col-12 px-0"}><EnlaceV>Tèrminos y condiciones</EnlaceV></Link>
                            <Link to={"/politicas_de_privacidad"} className={"col-12 px-0"}><EnlaceV>Políticas de privacidad</EnlaceV></Link>
                        </FLEX>
                    </FLEX>
                    <FLEX jc={"flex-start"} className={"col-12 px-0 pt-4 pt-lg-5"}>
                        <Franquicia src={"img/franquicias/logoepayco.png"}/>
                        <Franquicia src={"img/franquicias/visa.png"}/>
                        <Franquicia src={"img/franquicias/masterc.png"}/>
                        <Franquicia src={"img/franquicias/american.png"}/>
                        <Franquicia src={"img/franquicias/dinners.png"}/>
                        <Franquicia src={"img/franquicias/pse.png"}/>
                        <Franquicia src={"img/franquicias/safetypay.png"}/>
                    </FLEX>
                </FLEX>
                <FLEX className={"px-0 pt-3 col-12 "} >
                    <div className="col-12 col-md-6 px-0" css={cb}>
                       <TextoB size={"14px"}> © Copyright 2019. Todos los derechos reservados.</TextoB>
                    </div>
                    <div className="col-12 col-md-6 text-md-right"></div>
                </FLEX>
            </FLEX>
        </Myfooter>
     }
    </Fragment>
    )
}

export default withRouter(Footer);