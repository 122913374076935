export const CHANGE_MOD_PROD = "[GLOBAL] CHANGE_MOD_PROD";
export const CHANGE_LANGUAGE = "[GLOBAL] CHANGE_LANGUAGE";
export const CHANGE_CURRENCY = "[GLOBAL] CHANGE_CURRENCY";
export const CHANGE_ORDER = "[GLOBAL] CHANGE_ORDER";
export const CHANGE_ORDER_ITEM = "[GLOBAL] CHANGE_ORDER_ITEM";

export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'USERS_LOGOUT',
};

export const CHANGE_CLIENT = "[CLIENTS] CHANGE_CLIENT";
export const CHANGE_CLIENT_INPUT = "[CLIENTS] CHANGE_CLIENT_INPUT";

export const CHANGE_CONTACT = "[CLIENTS] CHANGE_CONTACT";
export const CHANGE_CONTACT_INPUT = "[CLIENTS] CHANGE_CONTACT_INPUT";
