import React from 'react';
import PropTypes from 'prop-types';
import {createMuiTheme, withStyles} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import {Route, Switch} from 'react-router-dom'
import Navigator from './Navigator';
import {Clients, Dashboard, Domains, Transactions} from '../contents';
import Header from './Header';
import Footer from './Footer';
import withGlobal from "../../providers/withGlobal";
import BreadCumbs from "./BreadCumbs";
import {withSnackbar} from "notistack";
import {actionsToast} from "../../providers/actionsToast";
import NewClient from "../contents/Clients/newClient";
import NewDomains from "../contents/Domains/newDomain";
import Prices from "../contents/PricesList";
import DetailClient from "../contents/Clients/detail";
import DetailDomain from "../contents/Domains/detail";
import RenewDomain from "../contents/Domains/renewDomain";
import Contacts from "../contents/Contacts";
import NewContact from "../contents/Contacts/newContact";
import DetailContact from "../contents/Contacts/detail";
import Profile from "../contents/Profile";

let theme = createMuiTheme({
    typography: {
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    palette: {
        primary: {
            light: '#63ccff',
            main: '#04BC00',
            dark: "#04BC05",
            contrastText: "white",
        },
    },
    shape: {
        borderRadius: 8,
    },
});

theme = {
    ...theme,
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: '#FFAB07',
            },
        },
        MuiButton: {
            label: {
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                '&:active': {
                    boxShadow: 'none',
                },
            },
        },
        MuiTabs: {
            root: {
                marginLeft: theme.spacing(1),
            },
            indicator: {
                height: 3,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: theme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                margin: '0 16px',
                minWidth: 0,
                padding: 0,
                [theme.breakpoints.up('md')]: {
                    padding: 0,
                    minWidth: 0,
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#F6F6F6',
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit',
                marginRight: 0,
                '& svg': {
                    fontSize: 20,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        ...theme.mixins,
        toolbar: {
            minHeight: 48,
        },
    },
};

const drawerWidth = 256;

const styles = {
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appContent: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
        overflow: "hidden",
        overflowY: "auto",
        justifyContent: "flex-start",
        zIndex: 1,
        flex: "5 1 auto",
    },
    mainContent: {
        flex: 1,
        padding: '36px 36px 0',
        background: '#eaeff1',
    },
};

class Main extends React.Component {

    state = {
        mobileOpen: false,
    };

    handleDrawerToggle = () => {
        this.setState(state => ({mobileOpen: !state.mobileOpen}));
    };

    handleMessage = (message, type) => {
        this.props.enqueueSnackbar(message, {
            variant: type,
            action: actionsToast(this.props)
        })
    };

    render() {
        const {classes, language} = this.props;

        return (
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    <CssBaseline/>
                    <nav className={classes.drawer}>
                        <Hidden smUp implementation="js">
                            <Navigator
                                PaperProps={{style: {width: drawerWidth}}}
                                variant="temporary"
                                open={this.state.mobileOpen}
                                onClose={this.handleDrawerToggle}
                                language={language}
                            />
                        </Hidden>
                        <Hidden xsDown implementation="css">
                            <Navigator PaperProps={{style: {width: drawerWidth}}} language={language}/>
                        </Hidden>
                    </nav>
                    <div className={classes.appContent}>
                        <Header onDrawerToggle={this.handleDrawerToggle} history={this.props.history}/>
                        <BreadCumbs history={this.props.history} language={language}/>
                        <main className={classes.mainContent}>
                            <Switch>
                                <Route exact path="/Dashboard"
                                       render=
                                           {props =>
                                               <Dashboard {...props} handleMessage={this.handleMessage}/>
                                           }
                                />
                                <Route exact path="/Clients"
                                       render=
                                           {props =>
                                               <Clients {...props} handleMessage={this.handleMessage}/>
                                           }
                                />
                                <Route exact path="/Clients/:id"
                                       render=
                                           {props =>
                                               <NewClient {...props} handleMessage={this.handleMessage}/>
                                           }
                                />
                                <Route exact path="/Clients/Detail/:id"
                                       render=
                                           {props =>
                                               <DetailClient {...props} handleMessage={this.handleMessage}/>
                                           }
                                />

                                <Route exact path="/Contacts"
                                       render=
                                           {props =>
                                               <Contacts {...props} handleMessage={this.handleMessage}/>
                                           }
                                />
                                <Route exact path="/Contacts/:id"
                                       render=
                                           {props =>
                                               <NewContact {...props} handleMessage={this.handleMessage}/>
                                           }
                                />
                                <Route exact path="/Contacts/Detail/:id"
                                       render=
                                           {props =>
                                               <DetailContact {...props} handleMessage={this.handleMessage}/>
                                           }
                                />


                                <Route exact path="/Domains"
                                       render=
                                           {props =>
                                               <Domains {...props} handleMessage={this.handleMessage}/>
                                           }
                                />
                                <Route exact path="/Domains/:id"
                                       render=
                                           {props =>
                                               <NewDomains {...props} handleMessage={this.handleMessage}/>
                                           }
                                />
                                <Route exact path="/Domains/Detail/:id"
                                       render=
                                           {props =>
                                               <DetailDomain {...props} handleMessage={this.handleMessage}/>
                                           }
                                />
                                <Route exact path="/Domains/Renew/:id"
                                       render=
                                           {props =>
                                               <RenewDomain {...props} handleMessage={this.handleMessage}/>
                                           }
                                />


                                <Route exact path="/Prices"
                                       render=
                                           {props =>
                                               <Prices {...props} handleMessage={this.handleMessage}/>
                                           }
                                />
                                <Route exact path="/Transactions"
                                       render=
                                           {props =>
                                               <Transactions {...props} handleMessage={this.handleMessage}/>
                                           }
                                />


                                <Route exact path="/Profile"
                                       render=
                                           {props =>
                                               <Profile {...props} handleMessage={this.handleMessage}/>
                                           }
                                />
                            </Switch>
                        </main>
                        <Footer language={this.props.language}/>
                    </div>
                </div>
            </ThemeProvider>
        );
    }
}

Main.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withSnackbar(withGlobal(withStyles(styles)(Main)));