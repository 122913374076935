import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {changeContact, changeContactInput} from '../store/actions/contactsActions';
import {serviceApiResponseDetele, serviceApiResponse} from "../services/request";

const mapStateToProps = ({global: {language}, contact: {contact}}) => ({
    contact,
    language
});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeContactInput,
    changeContact,
}, dispatch);


export default WrappedComponent => (connect(mapStateToProps, mapDispatchToProps)(
    class extends React.PureComponent {

        constructor(props) {
            super(props);

            this.state = {
                loading: false,
            };
            this.deleteContact = this.deleteContact.bind(this);
        }

        componentDidMount() {
            if (this.props.contact.id === "") {
                const id = window.location.pathname.split("/").slice(-1).pop();
                this.setState({loading: true});
                serviceApiResponse({}, `contacts/${id}`, "GET")
                    .then(response => {
                        if (response.id) {
                            this.props.changeContact(response);
                            this.setState({loading: false});
                        } else {
                            this.props.handleMessage("No se pudo consultar el Contacto", "error")
                        }
                    })
                    .catch(error => {
                        this.props.handleMessage(error.message, "error")
                        this.setState({loading: false});
                    })
            }
        }

        deleteContact() {
            serviceApiResponseDetele({}, `contacts/${this.props.contact.id}`, "DELETE")
                .then(response => {
                    if (response.status === 204) {
                        this.props.handleMessage("Contacto eliminado", "error");
                        this.props.history.push("/Contacts");
                    } else {
                        this.props.handleMessage(response.statusText, "error");
                    }
                }).catch(error => {
                this.props.handleMessage(error.message, "error");
                this.setState({loading: false});
            })
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    {...this.state}
                    deleteContact={this.deleteContact}
                />
            );
        }
    }
));