import React, {Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {CircularProgress, LinearProgress, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputLocal from "../../local/InputLocal";

const styles = {
    welcome: {
        color: "#05092C",
    },

    title: {
        fontSize: "18px",
        fontWeight: "bold",
        lineHeight: "1",
    },
};

const time = [
    {value: "1", name: "1 Año"},
    {value: "2", name: "2 Año"},
    {value: "3", name: "3 Año"},
    {value: "4", name: "4 Año"},
    {value: "5", name: "5 Año"},
];

const RenewDomain = (props) => {
    const {classes} = props;
    return (
        <Fragment>
            {props.loading ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LinearProgress/>
                    </Grid>
                </Grid>
                :
                <Grid container spacing={2}>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Card>
                            <CardHeader
                                disableTypography={true}
                                className={classes.title}
                                title={"Renovar dominio"}
                            />
                            <Divider light={true}/>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <InputLocal
                                        type={"text"}
                                        name={"dominio"}
                                        id={"dominio"}
                                        label={"Nombre del dominio"}
                                        placeholder={"Nombre del dominio"}
                                        disable={true}
                                    />
                                    <InputLocal
                                        type={"select"}
                                        options={time}
                                        name={"timeRenew"}
                                        id={"timeRenew"}
                                        label={"Renovar por"}
                                        placeholder={"Renovar por"}
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Card>
                            <CardHeader
                                disableTypography={true}
                                className={classes.title}
                                title={"Acciones"}
                            />
                            <Divider light={true}/>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            fullWidth
                                            color="inherit"
                                            className={classes.submit}
                                            onClick={() => props.history.push("/Domains")}
                                        >
                                            Volver
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            color="primary"
                            className={classes.submit}
                            onClick={(e) => props.handleSubmit(e)}
                        >
                            {props.loading ?
                                <CircularProgress
                                    size={24}
                                    color={"secondary"}/>
                                : "Renovar"
                            }
                        </Button>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Button
                            type="button"
                            variant="outlined"
                            fullWidth
                            color="inherit"
                            className={classes.submit}
                            onClick={() => props.history.push("/Domains")}
                            disabled={props.loading}
                        >
                            Cancelar
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                    </Grid>
                </Grid>
            }
        </Fragment>
    )
};

export default withStyles(styles)(RenewDomain);