import React from 'react';
import {Redirect, Route} from 'react-router-dom';
// import {Login} from "./index";
// import Remember from "./Login/remember";
import AppLanding from "../../componentsLanding";

export const PrivateRoute = ({component: Component, ...rest}) => (

    <Route {...rest} render={props => (
        localStorage.getItem('user') && props.history.location.pathname !== "/" && props.history.location.pathname !== "/nosotros" && props.history.location.pathname !== "/dominios" && props.history.location.pathname !== "/registro" && props.history.location.pathname !== "/checkout" && props.history.location.pathname !== "/precios" && props.history.location.pathname !== "/transferir" && props.history.location.pathname !== "/formas_de_pago" && props.history.location.pathname !== "/promociones" && props.history.location.pathname !== "/contacto" && props.history.location.pathname !== "/terminos_y_condiciones" && props.history.location.pathname !== "/politicas_de_privacidad" && props.history.location.pathname !== "/respuesta"
            ? <Component {...props}/>
            :
            // props.history.location.pathname === "/remember" ?
            //     <Remember/> :
            props.history.location.pathname === "/" || props.history.location.pathname === "/nosotros" || props.history.location.pathname === "/dominios" || props.history.location.pathname === "/login" || props.history.location.pathname === "/registro" || props.history.location.pathname === "/checkout" || props.history.location.pathname === "/precios" || props.history.location.pathname === "/transferir" || props.history.location.pathname === "/formas_de_pago" || props.history.location.pathname === "/promociones" || props.history.location.pathname === "/contacto" || props.history.location.pathname === "/terminos_y_condiciones" || props.history.location.pathname === "/politicas_de_privacidad" || props.history.location.pathname === "/respuesta" ?
                <AppLanding/> :
                <Redirect to={"/login"}/>
        // <Login/>
    )}/>
)