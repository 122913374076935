import {CHANGE_CONTACT, CHANGE_CONTACT_INPUT} from "../actions/nameActions";

const initialState = {
    contact: {
        id: "",
        honorificPrefix: "",
        givenName: "",
        familyName: "",
        worksFor: "",
        address: {
            id: "",
            addressCountry: "",
            addressLocality: "",
            streetAddress: "",
        },
        telephone: "",
        faxNumber: "",
        email: ""
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_CONTACT:
            return ({...state, contact: action.value});
        case CHANGE_CONTACT_INPUT:
            return ({...state, [action.name]: action.value});
        default:
            return ({...state})
    }
};