import {
    CHANGE_CLIENT,
    CHANGE_CLIENT_INPUT,
} from './nameActions'


///Aqui se empiezan a declarar las acciones globales de la aplicación,
export const changeClient = (value) => ({
    type: CHANGE_CLIENT,
    value
});

export const changeClientInput = (name,value) => ({
    type: CHANGE_CLIENT_INPUT,
    name,
    value
});
