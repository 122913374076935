
import styled from "@emotion/styled";

/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import {COLORS, ELASTIC, FLEX, Mask} from "../../ui";
import Select,{components} from "react-select";



const {Option} = components;

const Selectcont = styled.div`
    width:100%;
    background:transparent;
    color:white;
    font-weight:bold;
`

export const cb = css`
    color:white!important;
`


const IconOption = (props) => (
    <Option {...props}>
        <FLEX js={"flex-start"} flex={"1 0 auto"}>
            <ELASTIC className={"mr-2"}><Mask img={"banderas"} recuadro={"cuadro"} position={props.data.position}/></ELASTIC>
            <ELASTIC>{props.data.label}</ELASTIC>
        </FLEX>
    </Option>
);


const CurrencyOption = (props) => (
    <Option {...props}>
        <FLEX js={"flex-start"} flex={"1 0 auto"}>
            <ELASTIC className={"mr-2"}>
                <Mask img={"banderas"} recuadro={"cuadro"} position={props.data.position}/>
            </ELASTIC>
            <ELASTIC>{props.data.label}</ELASTIC>
        </FLEX>
    </Option>
);



const Selected = ({ children, ...props }) => (
    <components.SingleValue {...props}>
        <FLEX js={"flex-start"} flex={"0 0 80px"}>
            <ELASTIC className={"mr-2"}><Mask img={"banderas"} recuadro={"cuadro"} position={props.data.position}/></ELASTIC>
            <ELASTIC >
                <span css={cb}>{props.data.label}</span>
            </ELASTIC>
        </FLEX>
    </components.SingleValue>
);




const Myselectcont = ({children,...props}) =>
    <components.SelectContainer {...props}>
        <Selectcont>
            <FLEX jc={"flex-start"} className={" px-0"}>
                {children}
            </FLEX>
        </Selectcont>
    </components.SelectContainer>


const options = [
    {
        label:"ES",
        value:"ES",
        position:"100%",
    },
    {
        label:"EN",
        value:"EN",
        position:"100%",
    }
]

const CurrencyOptions = [
    // {
    //     label:"COP",
    //     value:"COP",
    //     position:"50%"
    //
    // },
    {
        label:"USD",
        value:"USD",
        position:"0"

    }
]
const NOOP = () => {};
// const NOOP_REACT_SELECT_STYLES = {
//     clearIndicator: NOOP,
//     container: NOOP,
//     control: NOOP,
//     dropdownIndicator: NOOP,
//     group: NOOP,
//     groupHeading: NOOP,
//     indicatorsContainer: NOOP,
//     indicatorSeparator: NOOP,
//     input: NOOP,
//     loadingIndicator: NOOP,
//     loadingMessage: NOOP,
//     // menu: NOOP,
//     // menuList: NOOP,
//     // menuPortal: NOOP,
//     multiValue: NOOP,
//     multiValueLabel: NOOP,
//     multiValueRemove: NOOP,
//     noOptionsMessage: NOOP,
//     option: NOOP,
//     placeholder: NOOP,
//     singleValue: NOOP,
//     valueContainer: NOOP
// };


 const Myselect =(props) =>{
    return(
        <Select options={options}
                onChange={(e)=>props.changeLanguage(e.value)}
                components={{
                    Option: IconOption,
                    SingleValue:Selected,
                    Control:Myselectcont,
                    IndicatorSeparator:null
                }}

                styles={{
                    singleValue: NOOP,
                    // container: NOOP,
                    control: NOOP,
                    dropdownIndicator:(base) =>({
                        ...base,
                        paddingLeft:"5px",
                        cursor:"pointer"
                    }),
                    indicatorsContainer: (base) =>({
                        ...base,
                    }),
                    valueContainer: () => ({
                        position:"relative"
                    }),
                    input:(base) => ({
                        ...base,
                        position:"absolute",
                        top:"0",
                        opacity:"1",
                        visibility:"visible",
                        border:"1px solis red"

                    }),

                    menuList: (base) => ({
                        ...base,
                        background:COLORS.azul,
                    }),

                    option: (base) => ({
                        ...base,
                        background:"transparent",
                        color:"white",
                    }),
                }}

                defaultValue={options.filter(it=>it.value===props.value)}
        />
    )
}


export const CurrencySelect =(props) =>{
    return(
        <Select options={CurrencyOptions}
                onChange={(e)=>props.changeCurrency(e.value)}
                components={{
                    Option: CurrencyOption,
                    SingleValue:Selected,
                    Control:Myselectcont,
                    IndicatorSeparator:null
                }}

                styles={{
                    singleValue: NOOP,
                    // container: NOOP,
                    control: NOOP,
                    dropdownIndicator:() =>({
                        paddingLeft:"5px",
                        cursor:"pointer"
                    }),
                    indicatorsContainer: NOOP,
                    valueContainer: () => ({
                        position:"relative"
                    }),
                    input:(base) => ({
                        ...base,
                        position:"absolute",
                        top:"0",
                        opacity:"1",
                        visibility:"visible",
                        border:"1px solis red"

                    }),

                    menuList: () => ({
                        background:COLORS.azul,
                    }),

                    option: (base) => ({
                        ...base,
                        background:"transparent",
                        color:"white",
                    }),
                }}

                defaultValue={CurrencyOptions.filter(it=>it.value===props.value)}
        />
    )
}

export default Myselect;