import {Switch, Route} from 'react-router'
import Loadable from 'react-loadable';

/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {FLEX} from "../ui";


const ContLoading = css`
    width:100%;
    min-height:calc(100vh - 397px);
`;

const CheckoutNull = () => localStorage.setItem("checkout", "0");

const Loading = () => {
    return (
        <FLEX css={ContLoading}>
            <h3>Cargando...</h3>
        </FLEX>
    )
}

const Home = Loadable({
    loader: () => {
        CheckoutNull();
        return (
            import('../home')
        )
    },
    loading: Loading,
});

const Nosotros = Loadable({
    loader: () => {
        CheckoutNull();
        return (
            import('../nosotros')
        )
    },
    loading: Loading,
});

const Dominios = Loadable({
    loader: () => {
        CheckoutNull();
        return (
            import('../dominios')
        )
    },
    loading: Loading,
});

const Login = Loadable({
    loader: () => import('../login'),
    loading: Loading,
});

const Checkout = Loadable({
    loader: () => {
        localStorage.setItem("checkout", "1");
        return (
            import('../checkout')
        )
    },
    loading: Loading,
});

const Prices = Loadable({
    loader: () => {
        CheckoutNull();
        return (
            import('../prices')
        )
    },
    loading: Loading,
});


const Transfer = Loadable({
    loader: () => {
        CheckoutNull();
        return (
            import('../transfer')
        )
    },
    loading: Loading,
});


const Payments = Loadable({
    loader: () => {
        CheckoutNull();
        return (
            import('../payments')
        )
    },
    loading: Loading,
});


const Promos = Loadable({
    loader: () => {
        CheckoutNull();
        return (
            import('../promos')
        )
    },
    loading: Loading,
});

const Contacto = Loadable({
    loader: () => {
        CheckoutNull();
        return (
            import('../contacto')
        )
    },
    loading: Loading,
});

const Terms = Loadable({
    loader: () => {
        CheckoutNull();
        return (
            import('../terms')
        )
    },
    loading: Loading,
});

const Response = Loadable({
    loader: () => {
        CheckoutNull();
        return (
            import('../respuesta')
        )
    },
    loading: Loading,
    timeout: 30000, // 10 seconds
});


const Routes = () => {

    return (
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/nosotros" component={Nosotros}/>
            <Route path="/dominios" component={Dominios}/>
            <Route path="/login" component={Login}/>
            <Route path="/registro" component={Login}/>
            <Route path="/checkout" component={Checkout}/>
            <Route path="/precios" component={Prices}/>
            <Route path="/transferir" component={Transfer}/>
            <Route path="/formas_de_pago" component={Payments}/>
            <Route path="/promociones" component={Promos}/>
            <Route path="/contacto" component={Contacto}/>
            <Route path="/terminos_y_condiciones" component={Terms}/>
            <Route path="/politicas_de_privacidad" component={Terms}/>
            <Route path="/respuesta" component={Response}/>
        </Switch>
    )
}

export default Routes;