import React, {Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
// import language from '../../../store/helpers/language';
import {LinearProgress, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputLocal from "../../local/InputLocal";
import withProfile from "../../../providers/withProfile";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
    welcome: {
        color: "#05092C",
    },

    title: {
        fontSize: "18px",
        fontWeight: "bold",
        lineHeight: "1",
    },
};

const docs = [
    {value: "CC", name: "CC"},
];

const Profile = (props) => {
    const {classes} = props;
    return (
        <Fragment>
            {props.loadPage ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LinearProgress/>
                    </Grid>
                </Grid>
                :
                <Grid container spacing={2}>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Card>
                            <CardHeader
                                disableTypography={true}
                                className={classes.title}
                                title={"Datos Basicos"}
                            />
                            <Divider light={true}/>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <InputLocal
                                        value={props.user.documentType}
                                        type={"select"}
                                        options={docs}
                                        name={"documentType"}
                                        id={"documentType"}
                                        label={"Tipo documento"}
                                        required={true}
                                        placeholder={"Tipo documento"}
                                        handleChange={(e) => props.handleInputChange(e)}
                                    />
                                    <InputLocal
                                        value={props.user.documentNumber}
                                        type={"text"}
                                        name={"documentNumber"}
                                        id={"documentNumber"}
                                        label={"Documento"}
                                        required={true}
                                        placeholder={"Documento"}
                                        handleChange={(e) => props.handleInputChange(e)}
                                    />
                                    <InputLocal
                                        value={props.user.givenName}
                                        type={"text"}
                                        name={"givenName"}
                                        id={"givenName"}
                                        label={"Nombres"}
                                        required={true}
                                        placeholder={"Nombre del contacto"}
                                        handleChange={(e) => props.handleInputChange(e)}
                                    />
                                    <InputLocal
                                        value={props.user.familyName}
                                        type={"text"}
                                        name={"familyName"}
                                        id={"familyName"}
                                        label={"Apelidos"}
                                        required={true}
                                        placeholder={"Apellido del contacto"}
                                        handleChange={(e) => props.handleInputChange(e)}
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Card>
                            <CardHeader
                                disableTypography={true}
                                className={classes.title}
                                title={"Acceso"}
                            />
                            <Divider light={true}/>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <InputLocal
                                        value={props.user.email}
                                        type={"text"}
                                        name={"email"}
                                        id={"email"}
                                        label={"Email"}
                                        required={true}
                                        placeholder={"Correo electronico"}
                                        handleChange={(e) => props.handleInputChange(e)}
                                    />
                                    <InputLocal
                                        type={"password"}
                                        name={"plainPassword"}
                                        id={"plainPassword"}
                                        label={"Contraseña"}
                                        required={true}
                                        placeholder={"Contraseña"}
                                        handleChange={(e) => props.handleInputChange(e)}
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}>
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            color="primary"
                            className={classes.submit}
                            onClick={(e) => props.handleSubmit(e)}
                        >
                            {props.loading ?
                                <CircularProgress
                                    size={24}
                                    color={"secondary"}/>
                                : "Guardar"
                            }
                        </Button>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Button
                            type="button"
                            variant="outlined"
                            fullWidth
                            color="inherit"
                            className={classes.submit}
                            onClick={() => props.history.push("/Dashboard")}
                            disabled={props.loading}
                        >
                            Cancelar
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                    </Grid>
                </Grid>
            }
        </Fragment>
    )
};

export default withProfile(withStyles(styles)(Profile));