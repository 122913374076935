import React from 'react';
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import InputLocalFull from '../../local/InputLocalFull'

import {CircularProgress, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";


const styles = {
    welcome: {
        color: "#05092C",
    },

    title: {
        fontSize: "18px",
        fontWeight: "bold",
        lineHeight: "1",
    },
};

const NewClient = (props) => {
    const {classes} = props;
    const [token, setToken] = React.useState('');
    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <Card>
                    <CardHeader
                        disableTypography={true}
                        className={classes.title}
                        title={"Registrar Dominio"}
                    />
                    <Divider light={true}/>
                    <CardContent>
                        <Grid container spacing={1}>
                            <InputLocalFull
                                type={"text"}
                                name={"token"}
                                id={"token"}
                                label={"Dominio"}
                                required={true}
                                placeholder={"Dominio"}
                                handleChange={(e) => setToken(e.target.value)}
                            />
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={4}>
            </Grid>

            <Grid item xs={4}>
                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="primary"
                    className={classes.submit}
                    onClick={() => window.open(`/dominios?token=${token}`)}
                >
                    {props.loading ?
                        <CircularProgress
                            size={24}
                            color={"secondary"}/>
                        : "Consultar"
                    }
                </Button>
            </Grid>
            <Grid item xs={4}>
                <Button
                    type="button"
                    variant="outlined"
                    fullWidth
                    color="inherit"
                    className={classes.submit}
                >
                    {props.loading ?
                        <CircularProgress
                            size={24}
                            color={"secondary"}/>
                        : "Cancelar"
                    }
                </Button>
            </Grid>

            <Grid item xs={12}>
            </Grid>
        </Grid>
    )
};

export default withStyles(styles)(NewClient);