import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import {changeClient, changeClientInput} from '../store/actions/clientsActions';
import {serviceApiResponse, serviceApiResponseLogin} from "../services/request";
import {actionsToast} from "./actionsToast";
import {loginSuccess} from '../store/actions/globalActions';
import jwtDecode from "jwt-decode";

const mapStateToProps = ({global: {language}, client: {client}}) => ({
    client,
    language
});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeClientInput,
    changeClient,
    loginSuccess
}, dispatch);


export default WrappedComponent => (connect(mapStateToProps, mapDispatchToProps)(
    class extends React.PureComponent {

        constructor(props) {
            super(props);

            this.state = {
                clientNew: {
                    id: 0,
                    terms: true,
                    addressCountry: "CO",
                    documentType: "CC",
                    confirmPassword: "",
                    plainPassword: "",
                },
                loading: false,
                loadingPage: true,
                loggedIn: false,
            };

            this.validator = new SimpleReactValidator(
                {
                    messages: {
                        required: "El campo es requerido",
                        string: "El campo debe tener mínimo 3 caracteres",
                        regex: "El email no es valido"
                    },
                    validators: {
                        samePassword: {
                            message: 'Las contraseñas deben coincidir',
                            rule: (val, params, validator) => {
                                return this.state.clientNew.plainPassword === this.state.clientNew.confirmPassword ? true : false;

                            }
                        },
                        pass: {  // name the rule
                            message: 'Su contraseña debe cumplir los siguientes requisitos:' +
                                'Debe tener al menos 12 caracteres,' +
                                'Debe contener al menos tres de los siguientes:' +
                                'un carácter en mayúscula,' +
                                'un carácter en minúscula,' +
                                'un número (0-9)' +
                                'un carácter especial (! @ # $% ^ & *)',
                            rule: (val, parameters, validator) => {
                                return this.validator.helpers.testRegex(this.state.clientNew.plainPassword, /^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$/i) &&
                                    this.state.clientNew.plainPassword.length >= 12;
                            },
                        }
                    }
                }
            );

            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleInputChangeCountry = this.handleInputChangeCountry.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
            this.handleSubmitNewRegister = this.handleSubmitNewRegister.bind(this);
        }

        handleSubmit(e) {
            e.preventDefault();
            if (this.validator.allValid()) {
                this.setState({loading: true});
                serviceApiResponse(this.state.clientNew, "users", "POST")
                    .then(response => {
                        if (response.violations) {
                            this.props.handleMessage(response.detail, "error")
                        } else {
                            this.props.handleMessage(`${this.state.clientNew.id > 0 ? "Actualizado" : "Creado"} exitosamente`, "success");
                            this.props.history.push("/Clients");
                        }
                        this.setState({loading: false});
                    }).catch(error => {
                    this.setState({loading: false});
                    this.props.handleMessage(error.message, "error")
                })
            } else {
                this.validator.showMessages();
                this.forceUpdate();
            }
        }

        handleSubmitNewRegister(index) {
            // let validForm = true;
            const validForm = this.validator.fieldValid("namesRegister") && this.validator.fieldValid("lastNameRegister") && this.validator.fieldValid("emailRegister") && this.validator.fieldValid("passwordRegister") && this.validator.fieldValid("confirmPasswordRegister");
            // if (index === 1) {
            // } else if (index === 2) {
            //     // validForm = this.validator.fieldValid("namesRegister") && this.validator.fieldValid("lastNameRegister");
            // } else {
            //     validForm = this.validator.fieldValid("emailRegister") && this.validator.fieldValid("passwordRegister");
            // }
            if (validForm) {
                console.log(index);
                const client = this.state.clientNew;
                // if (index > 2) {
                this.setState({loading: true});
                const data = {
                    email: client.email,
                    username: client.username,
                    plainPassword: client.plainPassword,
                    additionalEmail: client.email,
                    profile: {
                        documentType: client.documentType,
                        documentNumber: client.documentNumber,
                        honorificPrefix: "Sr.",
                        givenName: client.givenName,
                        familyName: client.familyName,
                        worksFor: client.worksFor,
                        address: {
                            addressCountry: client.addressCountry,
                            addressRegion: client.addressRegion,
                            addressLocality: client.addressLocality,
                            postalCode: client.postalCode,
                            streetAddress: client.streetAddress
                        },
                        telephone: client.addressCountryIndic || 57 + client.mobilephone,
                        mobilephone: client.addressCountryIndic || 57 + client.mobilephone,
                        faxNumber: "",
                    }
                };
                serviceApiResponse(data, "users", "POST")
                    .then(response => {
                        if (response.violations) {
                            this.props.enqueueSnackbar(response.detail, {
                                variant: 'error',
                                action: actionsToast(this.props)
                            });
                            this.setState({loading: false});
                        } else {
                            this.props.enqueueSnackbar("Creado exitosamente", {
                                variant: 'success',
                                action: actionsToast(this.props)
                            });

                            const data = {
                                username: client.email,
                                password: client.plainPassword
                            };
                            serviceApiResponseLogin(data, "login_check", "POST")
                                .then(response => {
                                    this.setState({loading: false});
                                    if (response.token) {
                                        const decoded = jwtDecode(response.token);
                                        const user = {
                                            user: decoded,
                                            token: response.token,
                                        };
                                        this.props.loginSuccess(user);
                                        localStorage.setItem('user', JSON.stringify(user));
                                        // this.setState({loggedIn: true})
                                        if (localStorage.getItem("checkout") === "SI") {
                                            this.props.history.push("/checkout");
                                            localStorage.setItem("checkout", "NO");
                                        } else {
                                            this.props.history.push("/Dashboard");
                                        }
                                    }
                                })
                                .catch(error => {
                                    this.setState({loading: false});
                                    this.props.enqueueSnackbar(error.message, {
                                        variant: 'error',
                                        action: actionsToast(this.props)
                                    })
                                })
                        }
                    }).catch(error => {
                    this.setState({loading: false});
                    this.props.enqueueSnackbar(error.message, {
                        variant: 'error',
                        action: actionsToast(this.props)
                    })
                })
                // } else {
                //     return true
                // }
            } else {
                this.validator.showMessages();
                this.forceUpdate();
                return false
            }
        }

        handleInputChange(e) {
            const name = e.target.name;
            const value = e.target.value;
            const {clientNew} = this.state;

            if (name === "email") {
                this.setState({clientNew: {...clientNew, [name]: value, username: value}});
            } else {
                this.setState({clientNew: {...clientNew, [name]: value}});
            }
        }

        handleInputChangeCountry(props) {
            this.setState({
                addressCountry: props.name,
                addressCountryIndic: props.value,
            });
        }

        componentDidMount() {

            const id = window.location.pathname.split("/").slice(-1).pop();
            this.setState({clientNew: id > 0 ? this.props.client : this.state.clientNew, loadingPage: false});
            if (this.props.client.id === "" && id > 0) {
                this.setState({loadingPage: true});
                serviceApiResponse({}, `users/${id}`, "GET")
                    .then(response => {
                        if (response.id) {
                            this.props.changeClient(response);
                            this.setState({clientNew: response, loadingPage: false});
                        } else {
                            this.props.handleMessage("No se pudo consultar el cliente", "error")
                        }
                    })
                    .catch(error => {
                        this.props.handleMessage(error.message, "error")
                        this.setState({loadingPage: false});
                    })
            }
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    {...this.state}
                    handleInputChange={this.handleInputChange}
                    handleInputChangeCountry={this.handleInputChangeCountry}
                    handleSubmit={this.handleSubmit}
                    error={
                        {
                            "email": this.validator.message('email', this.state.clientNew.email, ['required', {regex: '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'}]),
                            "password": this.validator.message('password', this.state.clientNew.password, 'required'),
                        }
                    }
                    handleSubmitNewRegister={this.handleSubmitNewRegister}
                    errorRegister={
                        {
                            "email": this.validator.message('emailRegister', this.state.clientNew.email, ['required', {regex: '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'}]),
                            "plainPassword": this.validator.message('passwordRegister', this.state.clientNew.plainPassword, 'required|pass'),
                            "confirmPassword": this.validator.message('confirmPasswordRegister', this.state.clientNew.confirmPassword, 'required|samePassword'),
                            "givenName": this.validator.message('namesRegister', this.state.clientNew.givenName, 'required'),
                            "familyName": this.validator.message('lastNameRegister', this.state.clientNew.familyName, 'required'),
                            "terms": this.validator.message('lastNameRegister', this.state.clientNew.terms, 'required'),
                            "addressCountry": this.validator.message('lastNameRegister', this.state.clientNew.addressCountry, 'required'),
                            "streetAddress": this.validator.message('lastNameRegister', this.state.clientNew.streetAddress, 'required'),
                        }
                    }
                />
            );
        }
    }
));