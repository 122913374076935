import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Contacts from '@material-ui/icons/Contacts';
import Group from '@material-ui/icons/Group';
import Cloud from '@material-ui/icons/Cloud';
import AttachMoney from '@material-ui/icons/AttachMoney';
import List from '@material-ui/icons/List';
import {Link} from 'react-router-dom'
import language from "../../store/helpers/language";

const style = {
    link: {
        color: "white",
        textDecoration: "none",
    }
};

export const ItemDashboard = (props) => (
    <Link to="/Dashboard" style={style.link}>
        <ListItem button>
            <ListItemIcon>
                <DashboardIcon/>
            </ListItemIcon>
            <ListItemText primary={language[props.language].Dashboard}/>
        </ListItem>
    </Link>
);

export const ItemClients = (props) => (
    <Link to={"/Clients"} style={style.link}>
        <ListItem button>
            <ListItemIcon>
                <Group/>
            </ListItemIcon>
            <ListItemText primary={language[props.language].Clients}/>
        </ListItem>
    </Link>
);

export const ItemContacts = (props) => (
    <Link to={"/Contacts"} style={style.link}>
        <ListItem button>
            <ListItemIcon>
                <Contacts/>
            </ListItemIcon>
            <ListItemText primary={language[props.language].Contacts}/>
        </ListItem>
    </Link>
);

export const ItemDomains = (props) => (
    <Link to={"/Domains"} style={style.link}>
        <ListItem button>
            <ListItemIcon>
                <Cloud/>
            </ListItemIcon>
            <ListItemText primary={language[props.language].Domains}/>
        </ListItem>
    </Link>
);

export const ItemTransactions = (props) => (
    <Link to={"/Prices"} style={style.link}>
        <ListItem button>
            <ListItemIcon>
                <List/>
            </ListItemIcon>
            <ListItemText primary={language[props.language].Prices}/>
        </ListItem>
    </Link>
);

export const ItemPrices = (props) => (
    <Link to={"/Transactions"} style={style.link}>
        <ListItem button>
            <ListItemIcon>
                <AttachMoney/>
            </ListItemIcon>
            <ListItemText primary={language[props.language].Transactions}/>
        </ListItem>
    </Link>
);

