import React, {Fragment} from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter, Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './store'
import Main from './components/layouts/main'
import {PrivateRoute} from "./components/contents/PrivateRoute";
import {SnackbarProvider} from "notistack";

const history = createBrowserHistory();

export default () => {


    return (
        <Provider store={store}>
            <div className="App main-container">
                <Fragment>
                    <Router history={history}>
                        <BrowserRouter>
                            <div>
                                <SnackbarProvider maxSnack={1} anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}>
                                    <PrivateRoute component={Main} history={history}/>
                                </SnackbarProvider>
                            </div>
                        </BrowserRouter>
                    </Router>
                </Fragment>
            </div>
        </Provider>
    )
}