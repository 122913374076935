import React from 'react';
import {Paper, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import language from "../../store/helpers/language";


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2, 2),
    },
}));

export default (props) => {

    const classes = useStyles();

    return (
        <footer>
            <Paper className={classes.root}>
                <Typography variant="inherit" color="textPrimary"
                            style={{fontSize: ".8rem", flex: "1 1 auto", marginTop: "15px"}}>
                    Resell Go © 2019 {language[props.language].Copyright}.
                </Typography>
            </Paper>
        </footer>
    )
}