import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import qs from 'qs';
import {
    changeModProd,
    changeLanguage
} from '../store/actions/globalActions';
import {changeClient} from '../store/actions/clientsActions';

import {
    logout
} from '../store/actions/userActions';
import {serviceApiResponse} from "../services/request";

const mapStateToProps = ({global: { userActive, language}, client: {client}}) => ({
    userActive,
    language,
    client,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeModProd,
    changeLanguage,
    logout,
    changeClient,
}, dispatch);


const headRows = [
    {id: 'id', numeric: false, disablePadding: true, visible: true, label: 'Id'},
    {id: 'email', numeric: false, disablePadding: false, visible: true, label: 'Email'},
    {id: 'username', numeric: false, disablePadding: false, visible: true, label: 'Usuario'},
    // {id: 'apellidos', numeric: false, disablePadding: false, label: 'Apellido'},
    // {id: 'pais', numeric: false, disablePadding: false, label: 'Pais'},
];

const rowsList = [
    "id",
    "email",
    "username",
    // "apellidos",
    // "pais",
];

export default WrappedComponent => (connect(mapStateToProps, mapDispatchToProps)(
    class extends React.PureComponent {

        constructor(props) {
            super(props);

            this.state = {
                rows: [],
                headRows,
                rowsList,
                load: true,
                search: "",

                count: 0,
                page: 0,
                rowsPerPage: 20
            };
            this.request = this.request.bind(this);
            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleChangePage = this.handleChangePage.bind(this);
            this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
            this.updateSearch = this.updateSearch.bind(this);
            this.updateClient = this.updateClient.bind(this);
            this.detailClient = this.detailClient.bind(this);
        }

        componentWillMount() {
            this.request()
        }

        request() {
            const {page, rowsPerPage, search} = this.state;
            let data = {
                itemsPerPage: rowsPerPage,
                page: page + 1,
            };
            if (search !== "") {
                // rowsList.map(row => (
                data = {...data, search}
                // ));
            }
            const str = qs.stringify(data);
            this.setState({load: true});
            serviceApiResponse({}, `users?${str}`, "GET")
                .then(response => {
                    // const {data, paginate_info} = response;
                    let totalCount = 0;
                    // if (response.status) {
                    totalCount = response['hydra:totalItems'];
                    this.setState({
                        rows: response["hydra:member"],
                        count: totalCount,
                    });
                    // } else {
                    //     this.props.handleMessage(response.message, "error")
                    // }
                    this.setState({load: false});
                })
                .catch(error => {
                    this.setState({load: false});
                    this.props.handleMessage(error.message, "error")
                })
        }

        handleChangePage(event, page) {
            this.setState({page}, this.request);
        }

        handleChangeRowsPerPage(event) {
            this.setState({rowsPerPage: +event.target.value}, this.request);
        }

        handleInputChange(e) {
            const name = e.target.name;
            const value = e.target.value;

            this.setState({...this.state, [name]: value});
        }

        updateSearch() {
            this.setState({search: ""}, () => this.request());
        }

        updateClient(id) {
            const dataClient = this.state.rows.filter((value) => {
                return value.id === id;
            });
            this.props.changeClient(dataClient[0]);
            this.props.history.push(`/Clients/${id}`);
        }

        detailClient(id) {
            const dataClient = this.state.rows.filter((value) => {
                return value.id === id;
            });
            this.props.changeClient(dataClient[0]);
            this.props.history.push(`/Clients/Detail/${id}`);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    {...this.state}
                    request={this.request}
                    handleInputChange={this.handleInputChange}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    updateSearch={this.updateSearch}
                    updateClient={this.updateClient}
                    detailClient={this.detailClient}
                />
            )
        }
    }
));