import {userConstants} from '../actions/nameActions';

let user = JSON.parse(localStorage.getItem('user'));
const initialState =
    user ? {
        loggedIn: true,
        user,
        submitted: false
    } : {
        loggedIn: false,
        submitted: false
    };

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user,
                submitted: true
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user,
                submitted: false
            };
        case userConstants.LOGIN_FAILURE:
            return {
                loggedIn: false,
                submitted: false
            };
        case userConstants.LOGOUT:
            return {
                submitted: false
            };
        default:
            return state
    }
}