import {fetch as fetchPolyfill} from 'whatwg-fetch'
import {userService} from "./servicesUser";

const baseUrl = process.env.REACT_APP_API_URL;

const serviceApiResponse = (data, url, method, file = false, requiredToken = true) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : "";
    let options =
        method === "POST" || method === "PUT" ?
            {
                method,
                headers: {
                    Authorization: requiredToken ? "Bearer " + token : null,
                    'Accept': 'application/json',
                    'Content-Type': file ? "multipart/form-data" : 'application/json',
                },
                body: JSON.stringify(data)
            } : {
                method,
                headers: {
                    Authorization: requiredToken ? "Bearer " + token : null,
                },
            };
    return (new Promise((resolve, reject) => {
        fetchPolyfill(baseUrl + url, options)
            .then(checkStatus)
            .then(parseJSON)
            .then(response => {
                resolve(response)
            }).catch(error => {
            reject(error);
        });
    }));
};

const serviceApiResponseLogin = (data, url, method) => {
    let options = {
        method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    };
    return (new Promise((resolve, reject) => {
        fetchPolyfill(baseUrl + url, options)
            .then(checkStatusLogin)
            .then(parseJSON)
            .then(response => {
                resolve(response)
            }).catch(error => {
            reject(error);
        });
    }));
};

const serviceApiResponseDetele = (data, url, method = "DELETE", requiredToken = true) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : "";

    let options = {
        method,
        headers: {
            Authorization: requiredToken ? "Bearer " + token : null,
        },
    };
    return (new Promise((resolve, reject) => {
        fetchPolyfill(baseUrl + url, options)
            .then(response => {
                resolve(response)
            }).catch(error => {
            reject(error);
        });
    }));
};

const parseJSON = (response) => {
    return response ? response.json() : true
};


const checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
        response.status = true;
        return response
    } else {
        if (response.status === 401) {
            window.location = "/login";
            userService.logout();
        }
        let error = new Error(response.statusText);
        if (response.status === 403) {
            error = new Error("El usuario no tiene acceso a esta acción");
        }
        if (response.status === 400) {
            response.status = false;
            return response;
        }
        if (response.status === 500) {
            error = new Error("Ocurrió un error interno en el servidor, por favor intenté mas tarde o comuníquese con el administrador.");
        }
        throw error
    }
};

const checkStatusLogin = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return response
    } else {
        if (response.status === 401) {
            throw new Error("Usuario y contraseña no valido");
        }
    }
};

const validateKeysObject = (object, key, returnNull = "") => {
    if (object[key] && object[key] !== null) {
        return object[key];
    } else {
        return returnNull
    }
};

export {
    serviceApiResponse,
    serviceApiResponseLogin,
    serviceApiResponseDetele,
    validateKeysObject,
}