import {
    CHANGE_CONTACT,
    CHANGE_CONTACT_INPUT,
} from './nameActions'


///Aqui se empiezan a declarar las acciones globales de la aplicación,
export const changeContact = (value) => ({
    type: CHANGE_CONTACT,
    value
});

export const changeContactInput = (name,value) => ({
    type: CHANGE_CONTACT_INPUT,
    name,
    value
});
