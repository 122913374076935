import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import {Send} from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import TableLocal from "../../local/TableLocal";
import TablePagination from "@material-ui/core/TablePagination";
import withContacts from "../../../providers/withContacts";
import {LinearProgress} from "@material-ui/core";

const styles = theme => ({
    paper: {
        maxWidth: 1300,
        margin: 'auto',
        overflow: 'hidden',
        marginBottom: "10px"
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        backgroundColor: "#04BC00",
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '10px 16px',
    },
    fieldsFilter: {
        marginRight: "10%"
    }
});


function Contacts(props) {
    const {classes, load, rows, headRows, page, rowsPerPage, count, rowsList, changeFilters} = props;


    const fieldsFilter = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justify="flex-start">
                        <Grid item>
                            <TextField
                                id="contacto-nombres"
                                label="Nombres"
                                name={"givenName"}
                                value={props.search.givenName}
                                variant="outlined"
                                margin="dense"
                                className={classes.fieldsFilter}
                                onChange={(e) => changeFilters(e)}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="contacto-apellidos"
                                label="Apellidos"
                                name={"familyName"}
                                value={props.search.familyName}
                                variant="outlined"
                                margin="dense"
                                className={classes.fieldsFilter}
                                onChange={(e) => changeFilters(e)}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="contacto-email"
                                label="Email"
                                name={"email"}
                                value={props.search.email}
                                variant="outlined"
                                margin="dense"
                                className={classes.fieldsFilter}
                                onChange={(e) => changeFilters(e)}
                            />
                        </Grid>
                        <Grid key={"3"} item xs={12} style={{marginTop: "1%"}}>
                            <Button variant="contained" color="primary" onClick={() => props.request()}>
                                {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                                <Send/>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <SearchIcon className={classes.block} color="inherit"/>
                        </Grid>
                        <Grid item xs>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                props.request()
                            }}>
                                <TextField
                                    value={props.search.id}
                                    name={"search"}
                                    fullWidth
                                    placeholder="Buscar"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: classes.searchInput,
                                    }}
                                    onChange={props.handleInputChange}
                                />
                            </form>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" className={classes.addUser}
                                    onClick={() => props.history.push("Contacts/0")}>
                                Nuevo Contacto
                            </Button>
                            <Tooltip title="Actualizar">
                                <IconButton onClick={() => props.updateSearch()}>
                                    <RefreshIcon className={classes.block} color="inherit"/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.contentWrapper}>
                {load ?
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                            <LinearProgress/>
                        </Grid>
                    </Grid>
                    : <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <TableLocal
                                rowsList={rowsList}
                                headRows={headRows}
                                rows={rows}
                                title={"Listado de contactos"}
                                check={true}
                                openFilter={() => props.openFilter()}
                                filter={props.filter}
                                fieldsFilter={() => fieldsFilter()}
                                actions={[
                                    {
                                        "name": "Editar",
                                        "funcion": (id) => props.updateContact(id),
                                        "value": "id",
                                    },
                                    {
                                        "name": "Detalle",
                                        "funcion": (id) => props.detailContact(id),
                                        "value": "id",
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TablePagination
                                rowsPerPageOptions={[20, 50, 100]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={props.handleChangePage}
                                onChangeRowsPerPage={props.handleChangeRowsPerPage}
                                labelRowsPerPage={"items por página"}
                                labelDisplayedRows={({from, to, count}) => `${from}-${to} de ${count} `}
                            />
                        </Grid>
                    </Grid>
                }
            </div>
        </Paper>
    );
}

Contacts.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withContacts(withStyles(styles)(Contacts));