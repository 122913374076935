import {combineReducers} from 'redux';
import global from './globalReducers';
import client from './clientsReducers';
import contact from './contactsReducers';
import {users} from './usersReducers';
import {authentication} from './authenticationReducer';


export default () => combineReducers({
    global, ///Reducer para los estados globales de la aplicación
    users,
    authentication,
    client,
    contact,
})