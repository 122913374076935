import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    changeLanguage
} from '../store/actions/globalActions';

import {serviceApiResponse} from "../services/request";

const mapStateToProps = ({global: {userActive, language}}) => ({
    userActive,
    language,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeLanguage,
}, dispatch);

export default WrappedComponent => (connect(mapStateToProps, mapDispatchToProps)(
    class extends React.PureComponent {

        constructor(props) {
            super(props);

            this.state = {
                loadPage: true,
                loading: false,
                user: {}
            };
            this.request = this.request.bind(this);
            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
        }

        componentWillMount() {
            this.request()
        }

        request() {

            const id = this.props.userActive.user.id;
            serviceApiResponse({}, "users/" + id, "GET")
                .then(response => {
                    this.setState({
                        loadPage: false,
                        user: {
                            id: id,
                            username: response.username,
                            email: response.username,
                            profileId: response.profile.id,
                            givenName: response.profile.givenName,
                            familyName: response.profile.familyName,
                            documentType: response.profile.documentType,
                            documentNumber: response.profile.documentNumber,
                        }
                    });
                })
                .catch(error => {
                    this.setState({loadPage: false});
                    this.props.handleMessage(error.message, "error")
                })
        }

        handleInputChange(e) {
            const name = e.target.name;
            const value = e.target.value;
            let {user} = this.state;

            this.setState({user: {...user, [name]: value}});
        }

        handleSubmit(e) {
            e.preventDefault();
            const id = this.props.userActive.user.id;
            const {user} = this.state;
            const data = {
                email: user.email,
                username: user.username,
                plainPassword: user.plainPassword,
                profile: {
                    id: user.profileId,
                    givenName: user.givenName,
                    familyName: user.familyName,
                    documentType: user.documentType,
                    documentNumber: user.documentNumber
                }
            };
            this.setState({
                loading: true,
            });
            serviceApiResponse(data, "users/" + id, "PUT")
                .then(response => {
                    if (response.detail) {
                        this.props.handleMessage(response.detail, "error")
                    } else {
                        this.props.handleMessage("Actualizado exitosamente.", "success")
                    }
                    this.setState({
                        loading: false,
                    });
                })
                .catch(error => {
                    this.setState({loading: false});
                    this.props.handleMessage(error.message, "error")
                })
        }

        render() {
            return (
                <WrappedComponent {...this.props}
                                  {...this.state}
                                  request={this.request}
                                  handleInputChange={this.handleInputChange}
                                  handleSubmit={this.handleSubmit}
                />
            );
        }
    }
));