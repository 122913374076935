import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import qs from 'qs';
import {
    changeModProd,
    changeLanguage
} from '../store/actions/globalActions';
import {changeContact} from '../store/actions/contactsActions';

import {
    logout
} from '../store/actions/userActions';
import {serviceApiResponse} from "../services/request";

const mapStateToProps = ({global: { userActive, language}, contact: {contact}}) => ({
    userActive,
    language,
    contact,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeModProd,
    changeLanguage,
    logout,
    changeContact,
}, dispatch);


const headRows = [
    {id: 'id', numeric: false, disablePadding: true, visible: true, label: 'Id'},
    {id: 'givenName', numeric: false, disablePadding: false, visible: true, label: 'Nombres'},
    {id: 'familyName', numeric: false, disablePadding: false, visible: true, label: 'Apellidos'},
    {id: 'worksFor', numeric: false, disablePadding: false, visible: true, label: 'Empresa'},
    // {id: 'address', numeric: false, disablePadding: false, label: 'Dirección'},
    {id: 'telephone', numeric: false, disablePadding: false, visible: true, label: 'Telefono'},
    // {id: 'mobilephone', numeric: false, disablePadding: false, label: 'Movil'},
    {id: 'email', numeric: false, disablePadding: false, visible: true, label: 'Email'},
    // {id: 'pais', numeric: false, disablePadding: false, label: 'Pais'},
];

const rowsList = [
    "id",
    "givenName",
    "familyName",
    "worksFor",
    // "address",
    "telephone",
    // "mobilephone",
    "email",
    // "pais",
];

export default WrappedComponent => (connect(mapStateToProps, mapDispatchToProps)(
    class extends React.PureComponent {

        constructor(props) {
            super(props);

            this.state = {
                rows: [],
                headRows,
                rowsList,
                load: true,
                search: {
                    order:"",
                    givenName:"",
                    familyName:"",
                    email:""
                },

                count: 0,
                page: 0,
                rowsPerPage: 20,
                filter:false,
            };
            this.request = this.request.bind(this);
            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleChangePage = this.handleChangePage.bind(this);
            this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
            this.updateSearch = this.updateSearch.bind(this);
            this.updateContact = this.updateContact.bind(this);
            this.detailContact = this.detailContact.bind(this);
        }

        componentWillMount() {
            this.request()
        }

        changeFilters(event){
            let target=event.target;
            let name=target.name;
            let value=target.value;

            this.setState({
                search:{
                    ...this.state.search,
                    [name]:value,
                }
            })
        }

        request() {
            const {page, rowsPerPage, search} = this.state;
            let data = {
                itemsPerPage: rowsPerPage,
                page: page + 1,
            };
            if (search !== "") {
                // rowsList.map(row => (
                data = {...data, ...search}
                // ));
            }
            const str = qs.stringify(data);
            this.setState({load: true});
            serviceApiResponse({}, `contacts?${str}`, "GET")
                .then(response => {
                    // const {data, paginate_info} = response;
                    let totalCount = 0;
                    // if (response.status) {
                    totalCount = response['hydra:totalItems'];
                    this.setState({
                        rows: response["hydra:member"],
                        count: totalCount,
                    });
                    // } else {
                    //     this.props.handleMessage(response.message, "error")
                    // }
                    this.setState({load: false});
                })
                .catch(error => {
                    this.setState({load: false});
                    this.props.handleMessage(error.message, "error")
                })
        }

        handleChangePage(event, page) {
            this.setState({page}, this.request);
        }

        handleChangeRowsPerPage(event) {
            this.setState({rowsPerPage: +event.target.value}, this.request);
        }

        handleInputChange(e) {
            const name = e.target.name;
            const value = e.target.value;

            this.setState({...this.state, [name]: value});
        }

        updateSearch() {
            this.setState({search: ""}, () => this.request());
        }

        updateContact(id) {
            const dataContact = this.state.rows.filter((value) => {
                return value.id === id;
            });
            this.props.changeContact(dataContact[0]);
            this.props.history.push(`/Contacts/${id}`);
        }

        detailContact(id) {
            const dataContact = this.state.rows.filter((value) => {
                return value.id === id;
            });
            this.props.changeContact(dataContact[0]);
            this.props.history.push(`/Contacts/Detail/${id}`);
        }

        openFilter(){
            this.setState({
                filter:!this.state.filter,
            })
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    {...this.state}
                    request={this.request}
                    handleInputChange={this.handleInputChange}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    updateSearch={this.updateSearch}
                    updateContact={this.updateContact}
                    detailContact={this.detailContact}
                    openFilter={()=>this.openFilter()}
                    changeFilters={this.changeFilters.bind(this)}
                />
            )
        }
    }
));