import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";
import {InputAdornment} from "@material-ui/core";

const InputLocalFull = (props) => {
    const {value, type, label, name, id, handleChange, placeholder, options, helperText, required, error, startAdornment, disabled=false, optionNull=true} = props;
    return (
        <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
            {type === "select" ?
                <TextField
                    defaultValue={value}
                    value={value}
                    margin="dense"
                    fullWidth
                    id={id}
                    name={name}
                    select
                    label={label}
                    // value={values.currency}
                    onChange={handleChange}
                    SelectProps={{
                        native: true,
                    }}
                    variant="outlined"
                    required={required}
                    disabled={disabled}
                    error={error}>
                    {
                        optionNull &&
                        <option value={""}/>
                    }
                    {options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </TextField>
                :
                <TextField
                    defaultValue={value}
                    error={error}
                    helperText={helperText}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    required={required}
                    name={name}
                    value={value}
                    label={label}
                    placeholder={placeholder}
                    type={type}
                    id={id}
                    onChange={handleChange}
                    autoComplete='nope'
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment>,
                    }}
                />
                // <TextField
                // id="standard-with-placeholder"
                // label={label}
                // placeholder={placeholder}
                // // className={classes.textField}
                // margin="normal"
                // />
            }
        </Grid>
    )
};

export default InputLocalFull