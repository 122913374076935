import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";

const InputLocal = (props) => {
    const {value, type, label, name, id, handleChange, placeholder, options, required, error, errorMessage,disabled} = props;
    return (
        <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            {type === "select" ?
                <TextField
                    defaultValue={value}
                    margin="dense"
                    fullWidth
                    id={id}
                    name={name}
                    select
                    label={label}
                    // value={values.currency}
                    onChange={handleChange}
                    SelectProps={{
                        native: true,
                    }}
                    helperText={errorMessage}
                    variant="outlined"
                    required={required}
                    error={error}
                    disabled={disabled}
                >
                    <option value={""}/>
                    {options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </TextField>
                :
                <TextField
                    disabled={disabled}
                    defaultValue={value}
                    error={error}
                    helperText={errorMessage}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    required={required}
                    name={name}
                    label={label}
                    placeholder={placeholder}
                    type={type}
                    id={id}
                    onChange={handleChange}
                    autoComplete='nope'
                />
                // <TextField
                // id="standard-with-placeholder"
                // label={label}
                // placeholder={placeholder}
                // // className={classes.textField}
                // margin="normal"
                // />
            }
        </Grid>
    )
};

export default InputLocal