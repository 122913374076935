import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import {Language} from '@material-ui/icons';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import withGlobal from "../../providers/withGlobal";

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
    secondaryBar: {
        zIndex: 0,
    },
    menuButton: {
        marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
        padding: 4,
    },
    link: {
        textDecoration: 'none',
        color: lightColor,
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    button: {
        borderColor: lightColor,
    },
    title: {
        flexGrow: 30,
    },
    logo: {
        maxWidth: "100px",
    }
});

function Header(props) {
    const {classes, onDrawerToggle} = props;
    const [perfil, setPerfil] = React.useState(null);
    const [viewLanguage, setViewLanguage] = React.useState(null);


    function handleClick(event) {
        setPerfil(event.currentTarget)
    }

    function handleClickLanguage(event) {
        setViewLanguage(event.currentTarget)
    }


    function handleClose() {
        setPerfil(null);
        setViewLanguage(null);
    }

    const handleCloseSession = () => {
        props.logout();
        setPerfil(null);
        props.history.push("/login");
    };

    const changeLanguage = (value) => {
        props.changeLanguage(value);
        setViewLanguage(null);
    };

    return (
        <React.Fragment>
            <AppBar color="primary" position="sticky" elevation={0} style={{backgroundColor: "#05092C"}}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">
                        <Hidden smUp>
                            <Grid item>
                                <IconButton
                                    color="inherit"
                                    aria-label="Open drawer"
                                    onClick={onDrawerToggle}
                                    className={classes.menuButton}
                                >
                                    <MenuIcon/>
                                </IconButton>
                            </Grid>
                        </Hidden>
                        <Grid/>
                        <Typography component="h6" variant="subtitle2" color="inherit" noWrap className={classes.title}>
                        </Typography>
                        <Grid item>
                            <IconButton color="inherit" onClick={handleClickLanguage}>
                                <Language/>
                                <Typography>
                                    {props.language}
                                </Typography>
                            </IconButton>
                            <Menu id="menu-language" anchorEl={viewLanguage} open={Boolean(viewLanguage)}
                                  onClose={handleClose}>
                                <MenuItem onClick={() => changeLanguage("ES")}>ES</MenuItem>
                                <MenuItem onClick={() => changeLanguage("EN")}>EN</MenuItem>
                            </Menu>
                        </Grid>
                        <Grid item>
                            <IconButton color="inherit">
                                <Badge badgeContent={0} color="secondary">
                                    <NotificationsIcon/>
                                </Badge>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton color="inherit" onClick={() => props.history.push("/checkout")}>
                                <Badge badgeContent={props.orderItems} color="secondary">
                                    <ShoppingCart/>
                                </Badge>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                edge="end"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={handleClick}
                            >
                                <Typography>
                                    {props.userActive.user.username}
                                </Typography>
                                {/*<AccountCircle/>*/}
                            </IconButton>
                            <Menu id="simple-menu" anchorEl={perfil} open={Boolean(perfil)} onClose={handleClose}>
                                <MenuItem onClick={() => {
                                    props.history.push("Profile");
                                    handleClose();
                                }}>Mi perfil</MenuItem>
                                <MenuItem onClick={handleCloseSession}>Cerrar Session</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    onDrawerToggle: PropTypes.func.isRequired,
};

export default withGlobal(withStyles(styles)(Header));