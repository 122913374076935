import React, {Fragment} from "react";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";

export const actionsToast = (props, key) => (
    <Fragment>
        <IconButton onClick={() => {
            props.closeSnackbar(key)
        }}>
            <Close/>
        </IconButton>
    </Fragment>
);