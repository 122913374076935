import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    changeModProd,
    changeLanguage
} from '../store/actions/globalActions';
import {changeClient} from '../store/actions/clientsActions';

import {
    logout
} from '../store/actions/userActions';
import {serviceApiResponse} from "../services/request";
import qs from "qs";

const mapStateToProps = ({global: {userActive, language}, client: {client}}) => ({
    userActive,
    language,
    client,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeModProd,
    changeLanguage,
    logout,
    changeClient,
}, dispatch);


const headRows = [
    {id: 'label', numeric: false, disablePadding: true, visible: true, label: 'TLD'},
    {id: 'createValue', numeric: false, disablePadding: false, visible: true, label: 'Nuevo'},
    {id: 'renewValue', numeric: false, disablePadding: false, visible: true, label: 'Renovar'},
    {id: 'transferValue', numeric: false, disablePadding: false, visible: true, label: 'Tranferir'},
    {id: 'restoreValue', numeric: false, disablePadding: false, visible: true, label: 'Restaurar'},
    {id: 'hasPromo', numeric: false, disablePadding: false, visible: false, label: 'Restaurar'},
];

const rowsList = [
    "label",
    "createValue",
    "renewValue",
    "transferValue",
    "restoreValue",
];

export default WrappedComponent => (connect(mapStateToProps, mapDispatchToProps)(
    class extends React.PureComponent {

        constructor(props) {
            super(props);

            this.state = {
                rows: [],
                headRows,
                rowsList,
                load: true,
                search: "",

                count: 0,
                page: 0,
                rowsPerPage: 20
            };
            this.request = this.request.bind(this);
            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleChangePage = this.handleChangePage.bind(this);
            this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
            this.updateSearch = this.updateSearch.bind(this);
        }

        componentWillMount() {
            this.request()
        }

        getData(date) {
            let fecha = new Date(date);
            let day = fecha.getDate().toString();
            let month = fecha.getMonth() + 1;

            if (fecha) {
                if (day < 10) day = "0".concat(day);
                if (month < 10) month = "0".concat(month.toString());
            }
            return `${fecha.getFullYear()}-${month}-${day}`

        }

        request() {
            const {page, rowsPerPage, search} = this.state;
            let data = {
                itemsPerPage: rowsPerPage,
                page: page + 1,
            };
            if (search !== "") {
                // rowsList.map(row => (
                data = {...data, search}
                // ));
            }
            const str = qs.stringify(data);
            this.setState({load: true});
            serviceApiResponse({}, `tlds?${str}`, "GET")
                .then(response => {
                    // const {data, paginate_info} = response;
                    let totalCount = 0;
                    // if (response.status) {
                    totalCount = response['hydra:totalItems'];
                    let data = response["hydra:member"];
                    if (data != null) {
                        data = data.map(dat => {
                            dat.createValue = dat.price ? `$${dat.price.createValue}` : "$0";
                            dat.renewValue = dat.price ? `$${dat.price.renewValue}` : "$0";
                            dat.transferValue = dat.price ? `$${dat.price.transferValue}` : "$0";
                            dat.restoreValue = dat.price ? `$${dat.price.restoreValue}` : "$0";
                            dat.hasPromo = dat.price ? dat.price.hasPromo : null;
                            dat.createPromoValue = dat.price ? dat.price.createPromoValue : null;
                            dat.renewPromoValue = dat.price ? dat.price.renewPromoValue : null;
                            dat.restorePromoValue = dat.price ? dat.price.restorePromoValue : null;
                            dat.transferPromoValue = dat.price ? dat.price.transferPromoValue : null;
                            dat.promoTo = dat.price ? dat.price.promoTo !== null ? this.getData(dat.price.promoTo) : null : null;
                            dat.promoFrom = dat.price ? dat.price.promoFrom !== null ? this.getData(dat.price.promoFrom) : null : null;
                            return (
                                dat
                            )
                        })
                    }
                    this.setState({
                        rows: data,
                        count: totalCount,
                    });
                    // } else {
                    //     this.props.handleMessage(response.message, "error")
                    // }
                    this.setState({load: false});
                    this.setState({load: false});
                })
                .catch(error => {
                    this.setState({load: false});
                    this.props.handleMessage(error.message, "error")
                })
        }

        handleChangePage(event, page) {
            this.setState({page}, this.request);
        }

        handleChangeRowsPerPage(event) {
            this.setState({rowsPerPage: +event.target.value}, this.request);
        }

        handleInputChange(e) {
            const name = e.target.name;
            const value = e.target.value;

            this.setState({...this.state, [name]: value});
        }

        updateSearch() {
            this.setState({search: ""}, () => this.request());
        }

        render() {
            return (
                <WrappedComponent {...this.props}
                                  {...this.state}
                                  request={this.request}
                                  handleInputChange={this.handleInputChange}
                                  handleChangePage={this.handleChangePage}
                                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                  updateSearch={this.updateSearch}
                />
            );
        }
    }
));