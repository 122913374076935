import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import {changeContact, changeContactInput} from '../store/actions/contactsActions';
import {serviceApiResponse} from "../services/request";

const mapStateToProps = ({global: {language}, contact: {contact}}) => ({
    contact,
    language
});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeContactInput,
    changeContact,
}, dispatch);


export default WrappedComponent => (connect(mapStateToProps, mapDispatchToProps)(
    class extends React.PureComponent {

        constructor(props) {
            super(props);

            this.state = {
                contactNew: {
                    id: 0,
                },
                loading: false,
                loadingPage: true,
            };

            this.validator = new SimpleReactValidator(
                {
                    messages: {
                        required: "El campo es requerido",
                        string: "El campo debe tener mínimo 3 caracteres",
                        regex: "El email no es valido"
                    },
                }
            );

            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
        }

        handleSubmit(e) {
            e.preventDefault();
            if (this.validator.allValid()) {
                this.setState({loading: true});
                const contact = this.state.contactNew;
                let data = {
                    honorificPrefix: contact.honorificPrefix,
                    givenName: contact.givenName,
                    familyName: contact.familyName,
                    worksFor: contact.worksFor,
                    address: {
                        addressCountry: contact.addressCountry,
                        addressLocality: contact.addressLocality,
                        streetAddress: contact.streetAddress
                    },
                    telephone: contact.telephone,
                    faxNumber: contact.faxNumber,
                    email: contact.email
                };
                if (contact.id > 0) {
                    let copyContact = Object.assign({}, data);
                    copyContact.address.id = contact.addressId;
                    data = copyContact;
                }
                console.log(data);
                serviceApiResponse(data, `contacts${contact.id > 0 ? `/${contact.id}` : ""}`, `${contact.id > 0 ? "PUT" : "POST"}`)
                    .then(response => {
                        if (response.violations || response.detail) {
                            this.props.handleMessage(response.detail, "error")
                        } else {
                            this.props.handleMessage(`${this.state.contactNew.id > 0 ? "Actualizado" : "Creado"} exitosamente`, "success");
                            this.props.history.push("/Contacts");
                        }
                        this.setState({loading: false});
                    }).catch(error => {
                    console.log(error);
                    this.setState({loading: false});
                    this.props.handleMessage(error.message, "error")
                })
            } else {
                this.validator.showMessages();
                this.forceUpdate();
            }
        }

        handleInputChange(e) {
            const name = e.target.name;
            const value = e.target.value;
            const {contactNew} = this.state;

            this.setState({contactNew: {...contactNew, [name]: value}});

        }

        componentDidMount() {
            const id = window.location.pathname.split("/").slice(-1).pop();
            if (id > 0) {
                const data = {
                    id: this.props.contact.id,
                    honorificPrefix: this.props.contact.honorificPrefix,
                    givenName: this.props.contact.givenName,
                    familyName: this.props.contact.familyName,
                    worksFor: this.props.contact.worksFor,
                    addressId: this.props.contact.address.id,
                    addressCountry: this.props.contact.address.addressCountry,
                    addressLocality: this.props.contact.address.addressLocality,
                    streetAddress: this.props.contact.address.streetAddress,
                    telephone: this.props.contact.telephone,
                    faxNumber: this.props.contact.faxNumber,
                    email: this.props.contact.email
                };
                this.setState({contactNew: id > 0 ? data : {}});
            }
            this.setState({loadingPage: false});
            if (this.props.contact.id === "" && id > 0) {
                this.setState({loadingPage: true});
                serviceApiResponse({}, `contacts/${id}`, "GET")
                    .then(response => {
                        if (response.id) {
                            const data = {
                                id: response.id,
                                honorificPrefix: response.honorificPrefix,
                                givenName: response.givenName,
                                familyName: response.familyName,
                                worksFor: response.worksFor,
                                addressId: response.address.id,
                                addressCountry: response.address.addressCountry,
                                addressLocality: response.address.addressLocality,
                                streetAddress: response.address.streetAddress,
                                telephone: response.telephone,
                                faxNumber: response.faxNumber,
                                email: response.email
                            };
                            this.props.changeContact(data);
                            this.setState({contactNew: data, loadingPage: false});
                        } else {
                            this.props.handleMessage("No se pudo consultar el Contacte", "error")
                        }
                    })
                    .catch(error => {
                        this.props.handleMessage(error.message, "error");
                        this.setState({loadingPage: false});
                    })
            }
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    {...this.state}
                    handleInputChange={this.handleInputChange}
                    handleSubmit={this.handleSubmit}
                    error={
                        {
                            "email": this.validator.message('email', this.state.contactNew.email, ['required', {regex: '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'}]),
                            "givenName": this.validator.message('givenName', this.state.contactNew.givenName, 'required'),
                            "familyName": this.validator.message('familyName', this.state.contactNew.familyName, 'required'),
                            "telephone": this.validator.message('telephone', this.state.contactNew.telephone, 'required'),
                            "streetAddress": this.validator.message('streetAddress', this.state.contactNew.streetAddress, 'required'),
                        }
                    }
                />
            );
        }
    }
));