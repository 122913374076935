import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    changeModProd,
    changeLanguage
} from '../store/actions/globalActions';
import {changeClient} from '../store/actions/clientsActions';

import {
    logout
} from '../store/actions/userActions';
import {serviceApiResponse} from "../services/request";
import qs from "qs";

const mapStateToProps = ({global: {userActive, language}, client: {client}}) => ({
    userActive,
    language,
    client,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeModProd,
    changeLanguage,
    logout,
    changeClient,
}, dispatch);


const headRows = [
    {id: 'id', numeric: false, disablePadding: true, label: 'Id Transacción', visible: true},
    {id: 'checkoutCompletedAt', numeric: false, disablePadding: false, label: 'Fecha transacción', visible: true},
    {id: 'createdAt', numeric: false, disablePadding: false, label: 'Fecha creación', visible: true},
    {id: 'createdBy', numeric: false, disablePadding: false, label: 'Cliente', visible: true},
    {id: 'notes', numeric: false, disablePadding: false, label: 'Descripción', visible: true},
    {id: 'total', numeric: true, disablePadding: false, label: 'Valor', visible: true},
    {id: 'paymentState', numeric: false, disablePadding: false, label: 'Medio de pago', visible: true},
];

const rowsList = [
    "id",
    "checkoutCompletedAt",
    'createdAt',
    "createdBy",
    "notes",
    "total",
    "paymentState",
];

export default WrappedComponent => (connect(mapStateToProps, mapDispatchToProps)(
    class extends React.PureComponent {

        constructor(props) {
            super(props);

            this.state = {
                rows: [],
                headRows,
                rowsList,
                load: true,
                search: "",

                count: 0,
                page: 0,
                rowsPerPage: 20
            };
            this.request = this.request.bind(this);
            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleChangePage = this.handleChangePage.bind(this);
            this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
            this.updateSearch = this.updateSearch.bind(this);
        }

        componentWillMount() {
            this.request()
        }

        request() {
            const {page, rowsPerPage, search} = this.state;
            let data = {
                itemsPerPage: rowsPerPage,
                page: page + 1,
            };
            if (search !== "") {
                data = {...data, search}
            }
            const str = qs.stringify(data);
            this.setState({load: true});
            serviceApiResponse({}, `orders?${str}`, "GET")
                .then(response => {
                    let totalCount = 0;
                    totalCount = response['hydra:totalItems'];
                    this.setState({
                        rows: response["hydra:member"],
                        count: totalCount,
                    });
                    this.setState({load: false});
                })
                .catch(error => {
                    this.setState({load: false});
                    this.props.handleMessage(error.message, "error")
                })
        }

        handleChangePage(event, page) {
            this.setState({page}, this.request);
        }

        handleChangeRowsPerPage(event) {
            this.setState({rowsPerPage: +event.target.value}, this.request);
        }

        handleInputChange(e) {
            const name = e.target.name;
            const value = e.target.value;

            this.setState({...this.state, [name]: value});
        }

        updateSearch() {
            this.setState({search: ""}, () => this.request());
        }

        render() {
            return (
                <WrappedComponent {...this.props}
                                  {...this.state}
                                  request={this.request}
                                  handleInputChange={this.handleInputChange}
                                  handleChangePage={this.handleChangePage}
                                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                  updateSearch={this.updateSearch}
                />
            );
        }
    }
));